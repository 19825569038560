<template> 

  <div class="container pt-4">

    <div v-if="!selectedSchema">

      Wybierz styl:
 
      <b-list-group class="mt-3">
        <b-list-group-item :class="{ 'text-primary': (item.type == 'label'), 'pl-3': (item.type !== 'label') }"  v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle" v-if="item.icon">{{ item.icon }}</span> {{ item.name }}
          <small v-if="item.description"><br/>{{ item.description }}</small>
        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
         
          </h2>
          <div v-if="this.selectedSchema.description"><br/>{{ this.selectedSchema.description }}</div>
          <hr/>
           
          <div class="mt-4">
               
            <div v-if="this.selectedSchema.fields.includes('WKLEJ FRAGMENT')">

              <label>Wklej fragment tekstu lub oferty, który chcesz poprawić:</label>
              
              <b-input-group>
          
                <b-form-textarea rows=10 v-model="fragment" ref="fragment" placeholder=""></b-form-textarea>
                
              </b-input-group>

            </div>
 
            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje ofertę dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana oferta</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppCopyBoosterAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        occupation: "",
        comment: "",
        industry: "",
        name: "",
        tematyka: "",
        problem: "",
        client: "",
        w_czym_pomagasz: "",
        style: "",
        fragment: "",
        pasteText: "",
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
        schema: [
          {
            name: "Wzbudzenie ciekawości",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając te zwroty: Co najciekawsze, Najciekawsze jest to, Najciekawszym faktem jest to, Niezmiernie ciekawe jest, Najbardziej zaciekawił mnie fakt..., To nie wszystko, jeszcze ciekawsze jest to, Kolejna niesamowita kwestia to fakt, że, A teraz najlepsze, A teraz najciekawsza część, Ten fragment cię zaciekawi a mianowicie, Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Power Words V1",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając te zwroty: bezpłatny, nowa, nowość, profesjonalny, gwarantowany, krytyczny, ostry, absolutnie, interesujący, wyzwanie, niezwykły, obsesyjny, wzmożony, wyjątkowy, niepowtarzalny, unikalny, poufny, pokaźny, masywny, cudowny. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          },
          {
            name: "Power Words V2",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając te zwroty dowozić efekty: skuteczny, piekielnie skuteczne, sekret sekretny, specjalny, wyjątkowa okazja, dożywotni, sprawdzony, najwyższej jakości, bestsellerowy, polecany, ulepszony, formuła, odważny, silny, natychmiastowy, pionier, pionierski, niezwykły, ograniczony. Oto tekst, który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          },
          {
            name: "Power Words V3",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając te zwroty: wybitny, uproszczony, pełen energii, potężny, autentyczny, prawdziwy, popularny, nurt, rzadki, wyłączny, silny, dający przewagę, cenny, atrakcyjny, ostatnia szansa, lepszy, łatwy w zastosowaniu, niezrównany, fascynujący, nieograniczony, konkurencyjny, gigantyczny, bezkompromisowy, nietypowy, bogaty. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          },
          {
            name: "Power Words V4",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając te zwroty: hojnie, innowacyjny, nagle, wspaniały, Uproszczony, przełomowy, pomocny, szybka, błyskawiczny, zyskowny, oszczędny, specjalny, sprytny, generujący wzrost, luksusowy, mocny, obiecujący, bezwarunkowy, bogactwo, zadziwiający, użyteczny. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          },
          {
            name: "Power Words V5",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z  tych zwrotów: pełen możliwości, trwały, bezpieczeństwo, zaawansowany, monumentalny, kolosalny, najnowszy, rewolucyjny, pilne, cudowny, formuła, niesamowity, magiczny, bezpośredni, autentyczny, ekscytujący, sprawdzony, prosty, wartość, wartościowy, niezwykle wartościowy, rewelacyjny, niespodzianka. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          },
          {
            name: "Power Words V6",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając te zwroty: prezent, poufny, praktyczny, doskonały, zachwycający, pobieranie, rozwijający, kompletny, idealny, perfekcyjny, każdego dnia, lider, koniecznie. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          },
          {
            name: "Tekst Hipnotyczny",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: Wyobraź sobie, że..., Zamknij oczy i wyobraź sobie jak, Nie wiem, czy możesz sobie to wyobrazić, ale..., A teraz oczami wyobraźni zobacz, Gdy czytasz teraz ten tekst i wyobrażasz sobie jak, Pomyśl teraz o tym, że..., Staraj się teraz nie myśleć o tym, że..., Weź głęboki wdech i wyobraź sobie, Poczuj ten moment, gdy..., Staraj się nie myśleć głęboko o..., Teraz wejdź głęboko w ten stan, wyobraź sobie jak... Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Tekst Hipnotyczny V2",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: To fantastyczne uczucie, gdy..., Nie wiem, która część Ciebie chciałaby teraz mieć coś takiego u siebie, Nie wiem w jaki sposób odczuwasz ciekawość, ale ten element na długo zapadnie Ci w pamięci, Czytasz ten słowa i twój umysł napełnia świadomość, Widzisz te czarne litery na białym tle i coraz bardziej uświadamiasz sobie, jak ważne jest, Czytasz te słowa i coraz bardziej masz chęć stać się posiadaczem...Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Storytelling V1",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: Przypomina mi się teraz pewna historia, To przypomniało mi pewna historię, Przypomina mi się teraz historia pewnej klientki, Pewna moja klientka opowiedział mi taka oto historię, Posłuchaj teraz pewnej historii, Kiedyś znajomy opowiedział mi taka oto historię, Krąży pewna opowieść, która potwierdza to co przed chwila napisałem, Opowiem Ci teraz pewną historię. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Storytelling V2",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: Posłuchaj tej krótkiej historii, która zmieni Twoje postrzeganie problemu, Żeby potwierdzić powyższe słowa opowiem Ci pewną historię, Posłuchaj tego. Kiedyś mój znajomy, Posłuchaj tego, kiedyś mój klient..., Kiedyś przydarzyła mi się taka oto sytuacja. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Storytelling V3",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: Pisząc o tym fakcie nie mogę przytoczyć pewnej historii, Żeby jeszcze bardziej unaocznić Ci jak to wszystko działa chcę opowiedzieć Ci pewną historię, W tym miejscu musze opowiedzieć Ci pewna historię, Ta część ebooka aż prosi się o wspomnienie pewnej historii, która na długo zapadła mi w pamięci, Posłuchaj pewnej opowieści, która potwierdza to co udało mi się napisać powyżej. Oto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Zwroty Zmysłowe V1",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: Zobacz, Spójrz na to z innej perspektywy, Posłuchaj tego, Posłuchaj, Słuchaj uważnie, Czy czujesz to?, Czujesz to? Poczuj jak to może wpłynąć na twoje życieOto tekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]"
          },
          {
            name: "Zwroty Zmysłowe V2",
            icon: "notes",
            fields: ["WKLEJ FRAGMENT"],
            command: "Dopracuj ten tekst pod względem copywriterskim dodając niektóre z tych zwrotów: Zobacz, jak możesz to zmienić, Warto dostrzec problem, Warto zauważyć głębszy problem, Zobacz do czego zmierzam, Spójrz na to w taki sposób, Zobacz teraz wynik, Obrazując całą sytuację, Spoglądając na to inaczej to, To jasne. Można na to patrzeć z różnych perspektywtekst który masz poprawić z użyciem powyższych zwrotów: [WKLEJ FRAGMENT]."
          }                              
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
  
        query = this.selectedSchema.command;

    
        if(this.$refs.fragment)
          query = query.replaceAll('[WKLEJ FRAGMENT]', this.fragment)
 

        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }
 
   

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana oferta"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Oferta została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>