<template> 

  <div>

    <Loader text="Ładowanie kursu" v-if="!user.id" />

    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">{{ course.title }}</h2>
        
      <div class="mt-3" v-if="!lesson.id">

        <b-tabs content-class="mt-3">

          <b-tab title="Prezentacja" active>
            
            <label class="mt-3">{{ course.subtitle }}</label>

            <h5 class="fw-600 mt-3">
              Opis kursu
            </h5>

            <div class="mb-3" v-if="course.description">
              {{ course.description }}
            </div>

            <b-link :to="('/course/' + course.slug + '/1')">Kliknij tutaj i przejdź do pierwszej lekcji</b-link>
        
            <div v-if="course.duration">

              <h5 class="fw-600 mt-5">
                Czas trwania 
              </h5>

              <div class="ml-1">
                Kurs trwa {{ course.duration }}h
              </div>
              
            </div>

            <h5 class="fw-600 mt-5" v-if="course.addons.length > 0">
              Dodatki
            </h5>
                  
            <div class="ml-1" v-if="course.addons.length > 0">
              Wraz z kursem dostępna jest dodatkowa zawartość:
              <br/>
              <br/>

              <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="item in course.addons" :key="item.title">

                <a :href="item.link" target="_blank">
                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'application'">
                    web_asset
                  </span>
                  
                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'course'">
                    play_lesson
                  </span> 

                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'forum'">
                    forum
                  </span> 

                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'workshop'">
                    group
                  </span>              

                    {{ item.title }}
                </a>
                  
              </b-list-group-item>

            </div>

          </b-tab>


          <b-tab title="Zawartość">
            
            <b-list-group>

                <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="item in course.lessons" :key="item.id">
      
                  <b-row class="w-100">

                    <b-col cols="1">

                      <span class="material-symbols-sharp align-middle" style="color:#dbdbdb;font-size:1rem;margin-top:-2px;" v-if="courseProgress.lessons.done.includes(parseInt(item.id))">
                        check_box 
                      </span>

                      <span class="material-symbols-sharp align-middle" style="color:#dbdbdb;font-size:1rem;margin-top:-2px;" v-if="!courseProgress.lessons.done.includes(parseInt(item.id))">
                        check_box_outline_blank
                      </span>

                    </b-col>

                    <b-col cols="10">
                      
                      <b-link :to="('/course/' + course.slug + '/' +  item.id)" :class="{ active: (item.id == lesson.id) }" class="text-dark">   
                        {{ item.title }}
                      </b-link>

                      <br/>

                      <span class="small bg-white" style="color: #818181;">
                        {{ item.duration }}
                      </span>
                  
                    </b-col>

                  </b-row>

                </b-list-group-item>

              </b-list-group>

          </b-tab>

          <b-tab title="Dodatki" v-if="course.addons.length > 0">
          
            <div class="mt-4">

              Wraz z kursem dostępna jest dodatkowa zawartość:
              <br/>
              <br/>

              <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="item in course.addons" :key="item.title">

                <a :href="item.link" target="_blank">
                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'application'">
                    web_asset
                  </span>
                  
                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'course'">
                    play_lesson
                  </span> 

                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'forum'">
                    forum
                  </span> 

                  <span class="material-symbols-sharp align-middle text-success" v-if="item.type == 'workshop'">
                    group
                  </span>              

                    {{ item.title }}
                </a>
                  
              </b-list-group-item>

            </div>

          </b-tab>

        </b-tabs>

      </div>
  
      <b-row v-if="lesson.id">
    
        <b-col lg="8" class="mt-5">    
          <label class="mb-2 fw-600 lesson-title">{{ lesson.title }}</label>
          <div class="bb"></div>
          <div class="mt-2">
            
            <div v-if="lesson.contentType == 'video-vimeo'" :class="{ 'embed-responsive': !vimeoOptions.responsive, 'embed-responsive-1by1': !vimeoOptions.responsive }">
              <vimeo-player ref="player" :video-id="lesson.contentData.videoId.toString()"  player-width="100%" :options="vimeoOptions" class="vimeo" width="100%;"/>
            </div>

            <div v-if="lesson.contentType == 'text'">
              <div v-html="lesson.contentData.text"></div>
            </div>

              
          </div>

          <b-row class="mt-3">
            <b-col lg="8">
              <!--<b-button variant="light" class="mt-2" block v-if="!courseProgress.lessons.done.includes(parseInt(lesson.id))" @click="markLessonAsDone()"> 
                <span class="material-symbols-sharp align-middle">
                  check
                </span> 
                Oznacz tą lekcję jako ukończoną
              </b-button>

              <b-button variant="light" class="mt-2" block v-if="courseProgress.lessons.done.includes(parseInt(lesson.id))" @click="markLessonAsUndone()">  
                <span class="material-symbols-sharp align-middle">
                  check
                </span> 
                Oznacz tą lekcję jako nieukończoną
              </b-button>-->


              <b-button target="_blank" variant="success" class="mt-2" block v-if="this.lesson.instantOfferLink" :href="this.lesson.instantOfferLink"> 
                <span class="material-symbols-sharp align-middle">
                  shopping_cart
                </span>
                {{ this.lesson.instantOfferText }} 
                <span class="material-symbols-sharp align-middle">
                  arrow_right
                </span> 
              </b-button>
            </b-col>
 

            <b-col lg="4">
              <b-button variant="light" class="mt-2" block v-if="this.lesson.id < this.course.lessons.length" @click="goToNextLesson()"> 
                Następna lekcja
                <span class="material-symbols-sharp align-middle">
                  arrow_right
                </span> 
              </b-button>
            </b-col>

          </b-row>

        </b-col>

        <b-col lg="4" class="mt-5">
          <label class="mb-3">
            <span class="material-symbols-sharp align-middle">play_lesson</span> Zawartość kursu</label> 
          <div class="bb"></div>
          
          <div class="mt-3 table-of-contents">
          
            <b-list-group>

              <b-list-group-item class="d-flex justify-content-between align-items-center " :class="{ 'current': (item.id == lesson.id) }" v-for="item in course.lessons" :key="item.id">
    
                <b-row class="w-100">

                  <b-col cols="1">

                    <span class="material-symbols-sharp align-middle" style="color:#dbdbdb;font-size:1rem;margin-top:-2px;" v-if="courseProgress.lessons.done.includes(parseInt(item.id))">
                      check_box 
                    </span>

                    <span class="material-symbols-sharp align-middle" style="color:#dbdbdb;font-size:1rem;margin-top:-2px;" v-if="!courseProgress.lessons.done.includes(parseInt(item.id))">
                      check_box_outline_blank
                    </span>

                  </b-col>

                  <b-col cols="10">
                    
                    <b-link :to="('/course/' + course.slug + '/' +  item.id)" :class="{ active: (item.id == lesson.id) }" class="text-dark">   
                      {{ item.title }}
                    </b-link>

                    <br/>

                    <span class="small bg-white" style="color: #818181;">
                      {{ item.duration }}
                    </span>
                
                  </b-col>

                </b-row>

              </b-list-group-item>

            </b-list-group>

          </div>

        </b-col>

      </b-row>
  
    </div>

  </div>
  
</template>

<script>
  
  import Loader from '../../elements/Loader';

  export default {
    name: 'SectionCourse',
    components: {
      Loader
    },
    data() {
      return {
        course: {},
        courseProgress: {},
        lesson: {},
        user: {
          id: null,
          permissions: []
        },
        vimeoOptions: {
          responsive: false,
          title: false,
          portrait: false,
          byline: false,
          controls: true,
          sidedock: true          
        }
      }
    },
 
    created() {
        
      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data 

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data.userId) {

          this.user.id = data.userId
          this.user.permissions = data.permissions


          if(this.$route.params.slug) {

            if(this.$route.params.lesson) 
              this.load(this.$route.params.slug, this.$route.params.lesson)
            else
              this.load(this.$route.params.slug);

          }

        }

      });

    },

    methods: {
        
      markLessonAsDone: function() {
        //api call
        this.courseProgress.lessons.done.push(parseInt(this.lesson.id))

      },

      markLessonAsUndone: function() {
        //api call
        var arr = this.courseProgress.lessons.done
        arr = arr.filter(item => item !== parseInt(this.lesson.id))
        
        this.courseProgress.lessons.done = arr

      },

      goToNextLesson: function() {
 
        let nextId = (parseInt(this.lesson.id) + 1);

        this.$router.push('/course/' + this.course.slug + '/' + nextId.toString())

      },

      getCourseProgress: function(slug) {
        
        this.axios.get(window.API_URL + "user/getCourseProgress/" + slug, { withCredentials: true }).then((response) => {

          this.courseProgress = response.data
    
        });

      },

      load: function(slug, lesson = false) {

        if(slug) {
 
          this.axios.get(window.API_URL + "courses/getCourse/" + slug, { withCredentials: true }).then((response) => {
            
            let data = response.data 
         
            this.course = data
            
            if(this.course.isVertical)
              this.vimeoOptions.responsive = false
            else
              this.vimeoOptions.responsive = true
            
            // Checking permissions
            if(!this.user.permissions.includes(this.course.neededPermissions)) {
              
              this.$router.push('/access-denied')
              
            }

            if(lesson) {

              this.lesson = this.course.lessons.find(x => x.id === lesson.toString())
              
              // Get user progress

              this.getCourseProgress(slug)

            }


            // Set breadcumbs
            let breadcumbs = [
              { label: "Kursy", link: "/courses" }
            ];

            this.$root.$emit('system.breadcumber.set', breadcumbs)

          }) 
 
          window.scrollTo(0, 0)

        }

      }

    },

    watch: {

      '$route.params.slug' (slug) {

        this.load(slug, this.lesson.id)

      },

      '$route.params.lesson' (lesson) {

        if(lesson == undefined) {
          this.$router.go('/course/' + this.course.slug)
        }
        else
        this.load(this.course.slug, lesson)

      },     

    },

    mounted() {
      this.getCourseProgress(this.course.slug)
    }

  }

</script>

<style scoped>

  .table-of-contents > div {
    font-size: 0.9rem;
  }

  .list-group-item {
    border: 0px;
  }

  .list-group-item > a {
    color: #000;
  }

  .list-group-item > a.active {
    color: #165eff;
    font-weight: 600;
  }

  .lesson-title {
    font-size: 1.3rem;
  }

  .vimeo {
    left: 0;
    top: 0;
    width: 100%;
  }

  .list-group-item:hover {
    background-color: #f9f9f9;
  }

  .list-group-item.current {
    background-color: #f9f9f9;
  }

</style> 