<template> 

  <div class="container pt-4">
     
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz temat artykułu:</label>

              <b-input-group>

                <b-form-input v-model="topic" size="lg" ref="topic" placeholder="np. fotowoltaika, diety, dieta keto, jak wybrać buty do biegania"></b-form-input>
                
              </b-input-group>
 
            </div>

             
            <div class="mt-3">

              <label>Wybierz styl autora:</label>

              <b-input-group>

                <b-form-select v-model="style" ref="style" size="lg" :options="styles"></b-form-select>
                
              </b-input-group>

            </div>
 
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj artykuł
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje artykuł <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany artykuł</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppArticleGeniusAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        query: "",
        topic: "",
        styles: [
          "Malcolm Gladwell - Autor i dziennikarz 'The New Yorker'",
          "Arianna Huffington - Założycielka The Huffington Post",
          "Bill Simmons - Sportowy kolumnista, założyciel The Ringer",
          "Glenn Greenwald - Dziennikarz śledczy, współzałożyciel The Intercept",
          "Ta-Nehisi Coates - Autor i dziennikarz 'The Atlantic'",
          "Andrew Sullivan - Bloger polityczny, były redaktor 'The New Republic'",
          "Fareed Zakaria - Autor i dziennikarz 'The Washington Post', gospodarz CNN",
          "Ezra Klein - Bloger polityczny, współzałożyciel Vox",
          "Maria Popova - Założycielka bloga Brain Pickings",
          "Nate Silver - Statystyk i pisarz, założyciel FiveThirtyEight",
          "Seth Godin - Autor i bloger biznesowy",
          "Matthew Yglesias - Bloger polityczny, współzałożyciel Vox",
          "Ann Friedman - Niezależna dziennikarka, autorka newslettera",
          "Mark Manson - Autor bestsellera 'The Subtle Art of Not Giving a F*ck'",
          "Tim Ferriss - Autor '4-Hour Workweek', bloger",
          "Roxane Gay - Kolumnistka, autorka 'Bad Feminist'",
          "Lindy West - Kolumnistka, autorka 'Shrill'",
          "Matt Taibbi - Autor, dziennikarz dla 'Rolling Stone'",
          "Thomas Friedman - Kolumnista 'The New York Times'",
          "Paul Krugman - Kolumnista 'The New York Times', laureat Nagrody Nobla",
          "Gideon Lichfield - Redaktor naczelny MIT Technology Review",
          "Kara Swisher - Kolumnistka 'The New York Times', współzałożycielka Recode",
          "Robert Peston - Redaktor polityczny ITV, były redaktor BBC",
          "Brian Stelter - Dziennikarz medialny, gospodarz 'Reliable Sources' w CNN",
          "Heather Cox Richardson - Profesor historii, autorka newslettera 'Letters from an American'",
          "Andrew Ross Sorkin - Kolumnista 'The New York Times', współzałożyciel DealBook",
          "Joanna Stern - Kolumnistka technologiczna 'The Wall Street Journal'",
          "Om Malik - Założyciel GigaOm",
          "Hunter S. Thompson - Pionier dziennikarstwa gonzo (zmarły)",
          "Marcy Wheeler - Niezależna dziennikarka śledcza, autorka bloga Emptywheel",
          "Ben Thompson - Założyciel i autor Stratechery",
          "John Gruber - Założyciel i autor Daring Fireball",
          "David Pogue - Autor i były kolumnista technologiczny 'The New York Times'",
          "Brian Krebs - Niezależny dziennikarz śledczy, specjalizujący się w bezpieczeństwie cybernetycznym",
          "Nilay Patel - Redaktor naczelny The Verge",
          "Joshua Topolsky - Współzałożyciel The Verge i Vox Media",
          "Tyler Cowen - Ekonomista, autor bloga Marginal Revolution",
          "Kevin Kelly - Współzałożyciel Wired, autor 'Cool Tools'",
          "Matthew Ingram - Dziennikarz medialny, autor dla Fortune",
          "Dave Winer - Programista, autor Scripting News, 'ojciec chrzestny' blogowania",
          "Julia Angwin - Dziennikarka śledcza, specjalizująca się w prywatności i technologii",
          "Megan McArdle - Kolumnistka 'The Washington Post'",
          "Mary Jo Foley - Dziennikarka specjalizująca się w technologiach Microsoft",
          "Ed Yong - Dziennikarz naukowy 'The Atlantic'",
          "Michael Arrington - Współzałożyciel TechCrunch",
          "Sarah Lacy - Współzałożycielka PandoDaily",
          "David Brooks - Kolumnista 'The New York Times'",
          "Bret Stephens - Kolumnista 'The New York Times'",
          "Michelle Malkin - Konserwatywna blogerka i kolumnistka",
          "Daniel Drezner - Profesor polityki, autor bloga dla 'The Washington Post'"
        ],
        style: "",
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {
  
      generateFinish: function() {

        if(!this.topic) return false;
        if(!this.style) return false;

        
        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
        
        query = "Wciel się w " + this.style + " i napisz artykuł w jego stylu, zachowując jego ton, strukturę, słownictwo i styl. Artykuł ma dotyczyć " + this.topic + ". Napisz tekst w języku polskim.";
 
        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany artykuł"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>