<template> 

  <div>
 
    <Loader text="Ładowanie aplikacji" v-if="!user.id" />

    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">Aplikacje</h2>

      <b-row>

        <!--<b-col lg="3" class="mt-5">
          Moje kurszy<br/>
          Wszystkie kursy<br/>
          <hr/>
          <small class="text-uppercase">Kategorie</small><br/><br/>
          TikTok<br/>
          Instagram<br/>
          Facebook<br/>
          E-marketing<br/>
          Copywriting<br/>
        </b-col>-->

        <b-col lg="12" class="pb-5">
  
          <b-form-input class="mt-5" v-model="search" placeholder="Szukaj aplikacji..."></b-form-input>
          
          <b-row class="d-flex">

            <b-col class="mt-3 d-flex" lg="3" sm="6" cols="6" v-for="item in sortedItems()" :key="item.slug">

              <b-card no-body class="overflow-hidden">
                
                <b-card-body>
 
                  <img :src="item.cover" style="width:100%;" class="mb-3"/>
                  
                  <h6 class="font-weight-bold" v-if="item.isBestseller">
                    <b-link :to="('/app/' + item.slug + '/')" class="text-dark">
                      <b-badge variant="success">BESTSELLER</b-badge> 
                    </b-link>
                  </h6>

                  <b-card-text class="small">

                    <div class="text-center mb-2">{{ item.subtitle }}</div>

                    <div style="color: #898989;" v-if="item.showMetaData">
                      <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">widgets</span> &nbsp; {{ item.addons.length }} dodatków<br/>
                    </div>
                    
                    <div style="height:100%;">
                      <div v-if="user.permissions.includes(item.neededPermissions)" class="text-center text-success">
                        <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">check_circle</span> Masz dostęp 

                        <b-button class="mt-2" variant="light" :to="('/app/' + item.slug + '/')" block size="sm" v-if="!item.isOldApp">Przejdź do aplikacji</b-button>
                        <b-button class="mt-2" variant="light" :href="(item.oldLink)" block size="sm" v-if="item.isOldApp">Przejdź do aplikacji</b-button>
                      </div> 

                      <div v-if="!user.permissions.includes(item.neededPermissions)" class="text-center text-danger">
                        <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">info</span> Nie masz dostępu 

                        <b-button class="mt-2" variant="success" :href="(item.buyLink)" block size="sm">Uzyskaj dostęp</b-button>
                      </div> 
                    </div>

                  </b-card-text>

                </b-card-body>
  
              </b-card>

            </b-col>

          </b-row>

        </b-col>

      </b-row>

    </div>
  </div>
</template>

<script>
import Loader from '../../elements/Loader';

export default {
  name: 'SectionApps',
  components: {
     Loader
  },
  data() {
    return {
      apps: [],
      sortBy: 'order',
      sortDirection: 'asc',
      search: '',
      user: {
        id: null,
        permissions: []
      }
    }
  },

  mounted() {


  },
  created() {
     
    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data 

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions


        this.axios.get(window.API_URL + "apps/getAllApps").then((response) => {

          let data = response.data

          data.data.forEach(element => {

            this.axios.get(window.API_URL + "apps/getApp/" + element.slug).then((response) => {

              var add = response.data;
              add.order = element.order;

              add.neededPermissions = element.neededPermissions;

              if(!add.isHidden) {

                if(add.showOnlyIfHasAPerms) {

                  if(this.user.permissions.includes(add.neededPermissions)) {

                    this.apps.push(add)

                  }

                } else {

                  this.apps.push(add)

                }

              }

            })

          })

        })


      }

    });



    // Set breadcumbs
    this.$root.$emit('system.breadcumber.set', [{label: "Aplikacje", link: "/apps"}])

  },
  methods: {
       
    sortedItems: function (){

      var search = this.search.toLowerCase();

      var arr = this.apps;

      if(search) {
      
        arr = arr.map(object => {
          object.name = object.name.toLowerCase()
          return object;
        });

        arr = arr.filter(function(el) { 
          return el.name.indexOf(search) > -1 
        }); 

      }

      return arr.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });

    }
      
  }
 
}
</script>

  