<template> 

  <div class="container pt-4 mb-5">
     
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz tytuł/tematykę książki:</label>

              <b-input-group>

                <b-form-input v-model="tytul" size="lg" ref="tytul" placeholder="np. marketing na TikToku, Jak zainstalować fotowoltaikę"></b-form-input>
                
              </b-input-group>
 
            </div>

          </div>

        </b-col>

        <b-col lg="12">

          <div class="mt-4">

            <div class="mt-3">

              <label>Wybierz ile rozdziałów ma mieć książka:</label>

              <b-input-group>

                <b-form-input type="number" min="1" max="50" v-model="ilosc" size="lg" ref="ilosc" placeholder=""></b-form-input>

              </b-input-group>

            </div>

          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje treść <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Twój ebook "{{ tytul }}"</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="lessons.length > 0" style="font-size: 1em;">
            
            <div class="mb-3 mt-3 p-3" style="font-size: 1em; line-height: 1.7em;">

              <b-row class="mt-2">

                <b-col lg="4" style="color: rgb(3, 80, 227);padding: 10px;border-right: 1px solid #ddd;">

                  <div v-for="item in lessons" :key="item" style="border-bottom: 1px solid #dddddd45;">

                    <span style="cursor: pointer;font-size:12px;" @click="getArticle(item.id, item.name)">
                      <span class="material-symbols-sharp align-middle" v-if="item.content">task_alt</span>
                      {{ item.id }}. {{ item.name }}</span><br/>

                  </div>

                </b-col>

                <b-col lg="8">

                  <div v-if="result2" v-html="result2" class="pb-3 mb-3" style="border-bottom: 1px solid #ddd;"></div>

                </b-col>

              </b-row>

            </div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>


            <div class="text-primary pointer small mt-1 float-right mr-3" @click="downloadAll">

              <span class="material-symbols-sharp align-middle">download</span>
              Pobierz cały ebook

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppArticleSEOIdeas',
    components: {
    },
    data() {
      return {
        isFinish: false,
        lessons: [],
        result2: "",
        selectedText: '',
        query: "",
        tytul: "",
        ilosc: 20,
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {

      getArticle: function (id, title) {

        if(!id) return false;
        if(!title) return false;

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result2 = ""

        this.lessons.forEach((item, index) => {

          if(item.id === id) {

            if(item.content) {

              doneText = item.content;

              this.finishContent = doneText;

              this.result2 = "<h2>" + item.name + "</h2>" + doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

              this.$refs.generatePost.disabled = false
              this.generatedPostTitle = "Wygenerowana treść"


            } else {

              var query = "Napisz mi długi artykuł na bloga o nazwie "+this.tytul+", do lekcji pt. " + title + ". Treść ma być długa i wyczerpująca. Powinna być napisana w stylu 7 rzeczy... 7 ważnych informacji ... jak krok po kroku zrobić xyz. Zakończ słowami w stylu zapraszam do kolejnej lekcji. Używaj tagów HTML zamiast markdown.";

              var doneText = "";

              this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query, 'v': '4' }, { withCredentials: true, timeout: 3000000 }).then((response) => {

                let data = response.data

                if(data.redirectTo) {

                  top.location.href = data.redirectTo;

                }

                doneText = data.result;
                doneText = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>')

                this.finishContent = doneText;

                this.lessons[index].content = doneText;

                this.result2 = doneText;


                this.$refs.generatePost.disabled = false
                this.generatedPostTitle = "Wygenerowana treść"

              }).catch(function() {

                this.$refs.generatePost.disabled = false

              })

            }

          }

        })







      },

      generateFinish: function() {

        if(!this.tytul) return false;
        if(!this.ilosc) return false;

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.lessons = []

        var query = "Napisz mi spis treści książki pt. " + this.tytul + ". Książa ma mieć " + this.ilosc + " rozdziałów. Wypisz tylko i wyłącznie tytuły lekcji, bez numeracji, bez myślników. Tytuł może zawierać tylko treść bez numeracji i bez myślników. NIE NUMERUJ WIERSZY, NIE DODAWAJ MYŚLNIKÓW.";

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          let txt = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          let lessons_tmp = txt.split('<br/>');

          var i = 1;

          lessons_tmp.forEach((item) => {

            this.lessons.push({
              id: i,
              name: item,
              content: ""
            })

            i++;

          })


          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana treść"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      },


      downloadAll: function() {

        this.finishContent = "Spis treści</br></br>";

        this.lessons.forEach((item) => {

          this.finishContent += item.id + ". " + item.name + "</br>"

        });

        this.finishContent += "</br></br>"

        this.lessons.forEach((item) => {

          this.finishContent += item.id + ". " + item.name + "</br>" + item.content + "</br></br></br>"

        });


        const data = this.finishContent
        const blob = new Blob([data], {type: 'text/html'})
        const e = document.createEvent('MouseEvents'),
            a = document.createElement('a');
        a.download = "moj-ebook.html";
        a.href = window.URL.createObjectURL(blob);
        a.dataset.downloadurl = ['text/html', a.download, a.href].join(':');
        e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
        a.dispatchEvent(e);

      }


    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>