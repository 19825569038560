<template> 

  <div>

    <Loader text="Ładowanie kursów" v-if="!user.id" />
   
    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">Kursy</h2>

      <b-row>

        <b-col lg="3" class="mt-5">

          <div class="text-uppercase small mb-2">Przeglądaj</div>

          <div style="cursor:pointer;" v-on:click="selectedTags = []">Wszystkie kursy</div>

          <hr/>
          
          <div v-for="tag in tags" :key="tag" class="mt-1" style="cursor:pointer;" v-on:click="addTagToFilter(tag)">
              <span class="material-symbols-sharp align-middle" v-if="selectedTags.includes(tag)">
                check_box
              </span> 
              <span class="material-symbols-sharp align-middle" v-if="!selectedTags.includes(tag)">
                check_box_outline_blank
              </span> 
              {{ tag }}
          </div>
          
        </b-col>

        <b-col lg="9">

          <b-form-input class="mb-2 mt-3" v-model="search" placeholder="Szukaj kursu..."></b-form-input>

          <b-row>

            <b-col class="mt-3" lg="4" sm="6" cols="6" v-for="item in sortedItems()" :key="item.slug">

              <b-card no-body class="overflow-hidden">
                
                <b-card-body>
                     
                  <a :href="('/course/' + item.slug + '/')"  class="mb-3" v-if="!item.availableFrom">
                    <img :src="item.cover" style="width:100%;"/>
                  </a>

                  <a class="mb-3" v-if="item.availableFrom">
                    <img :src="item.cover" style="width:100%;"/>
                  </a>
                  
                  <h6 class="font-weight-bold">

                    <span class="text-dark" v-if="item.availableFrom">
                      <b-badge variant="success" v-if="item.isBestseller">BESTSELLER</b-badge> 
                      {{ item.title }}
                    </span>

                    <a :href="('/course/' + item.slug + '/')" class="text-dark" v-if="!item.availableFrom">
                      <b-badge variant="success" v-if="item.isBestseller">BESTSELLER</b-badge>
                      {{ item.title }}
                    </a>

                  </h6>

                  <b-card-text class="small">

                    {{ item.subtitle }}
  
                    <br/>
                    <div style="color: #898989;" class="mt-2" v-if="item.showMetaData && (!item.availableFrom)">
                      <div v-if="item.duration">
                        <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">schedule</span> &nbsp; Czas trwania {{ item.duration }}h<br/>
                      </div>
                      <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">play_lesson</span> &nbsp; Lekcje: {{ item.lessons.length }}<br/>
                      <div v-if="item.addons.length > 0">
                        <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">widgets</span> &nbsp; {{ item.addons.length }} dodatków
                      </div>
                    </div>
                    <br/>
                    
                    <div v-if="(!item.availableFrom) && user.permissions.includes(item.neededPermissions)" class="text-center text-success">
                      <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">check_circle</span> Masz dostęp 

                      <b-button class="mt-2" variant="light" :to="('/course/' + item.slug + '/')" block size="sm" v-if="!item.isOldCourse">Przejdź do kursu</b-button>
                      <b-button class="mt-2" variant="light" :href="(item.oldLink)" block size="sm" v-if="item.isOldCourse">Przejdź do kursu</b-button>
                    </div>

                    <div v-if="!user.permissions.includes(item.neededPermissions)" class="text-center text-danger">
                      <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">info</span> Nie masz dostępu 

                      <b-button class="mt-2" variant="success" :href="(item.buyLink)" block size="sm">Uzyskaj dostęp</b-button>
                    </div>

                    <div v-if="item.availableFrom">
                      Dostępne od: {{ item.availableFrom }}
                    </div>

                  </b-card-text>

                </b-card-body>
  
              </b-card>

            </b-col>

          </b-row>

        </b-col>

      </b-row>
  
    </div>

  </div>

</template>

<script>
import Loader from '../../elements/Loader';

export default {
  name: 'SectionCourses',
  components: {
     Loader
  },
  data() {
    return {
      courses: [],
      sortBy: 'order',
      sortDirection: 'asc',
      search: '',
      tags: [
        "AI",
        "Blogowanie",
        "Copywriting",
        "E-booki",
        "E-mail marketing",
        "E-marketing",
        "Facebook",
        "Instagram",
        "Lista mailingowa",
        "Nagrania z Mastermind",
        "Podcasty",
        "Produktywność",
        "SEO",
        "Snapchat",
        "YouTube",
        "TikTok"
      ],
      selectedTags: [],
      user: {
        id: null,
        permissions: []
      }
    }
  },

  mounted() {
    



  },
  created() {
    
    this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

      let data = response.data 

      if(data.redirectTo) {

        top.location.href = data.redirectTo;

      }

      if(data.userId) {

        this.user.id = data.userId
        this.user.permissions = data.permissions


        this.axios.get(window.API_URL + "courses/getAllCourses").then((response) => {

          let data = response.data

          data.data.forEach(element => {

            this.axios.get(window.API_URL + "courses/getCourse/" + element.slug).then((response) => {

              var add = response.data;
              add.order = element.order;
              add.neededPermissions = element.neededPermissions;

              if(add.showOnlyIfHasAPerms) {

                if(this.user.permissions.includes(add.neededPermissions)) {

                  this.courses.push(add)

                }

              } else {

                this.courses.push(add)

              }


            })

          })

        })

        
      }

    });




    // Set breadcumbs
    this.$root.$emit('system.breadcumber.set', [])

  },
  methods: {
      
    addTagToFilter: function(tag) {

      if(!this.selectedTags.includes(tag)) {

        this.selectedTags.push(tag)

      } else {

        this.selectedTags = this.selectedTags.filter(item => item !== tag)

      }

      
    },

    sortedItems: function (){
      
      var search = this.search.toLowerCase();

      var arr = this.courses;

      // Selected tags
      if(this.selectedTags.length > 0) {

        arr = [];

        this.courses.forEach((element) => { 
          
          var add = false

          element.tags.forEach((tag) => { 

            if(this.selectedTags.includes(tag)) {

              add = true

            }

          })

          if(add)
            arr.push(element);

        })
        
        /*element.tags.forEach((tag) => {

            if(this.selectedTags.includes(tag))
              arr.push(element);
          })*/
        //arr = arr.filter((allNameObject) => this.selectedTags.includes(allNameObject))

      }

      if(search) {
      
        arr = arr.map(object => {
          object.name = object.name.toLowerCase()
          return object;
        });

        arr = arr.filter(function(el) { 
          return el.name.indexOf(search) > -1 
        }); 

      }

      return arr.slice().sort(function(a, b){
        return (a.order > b.order) ? 1 : -1;
      });
      
    }
      
  }
}
</script>

  