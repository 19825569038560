<template> 

  <div class="container pt-4 mb-5">
     
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz produkt, który będzie w promocji przez 3 dni:</label>

              <b-input-group>

                <b-form-input v-model="produkt" size="lg" ref="produkt" placeholder="np. wędka spinningowa, mikrofon do telefonu"></b-form-input>
                
              </b-input-group>
 
            </div>

            
            <div class="mt-3">

              <label>Wpisz miesiąc, w którym chcesz przeprowadzić akcję sprzedażową</label>

              <b-input-group>

                <b-form-input v-model="miesiac" size="lg" ref="miesiac" placeholder="np. luty, czerwiec"></b-form-input>
                
              </b-input-group>

            </div>

            
            <div class="mt-3">

              <label>Napisz, kiedy dokładnie kończy się akcja sprzedażowa</label>

              <b-input-group>

                <b-form-input v-model="koniec" size="lg" ref="koniec" placeholder="np. czwartek 20 lutego"></b-form-input>
                
              </b-input-group>

            </div>


            <div class="mt-3">

              <label>Opisz w kilku zdaniach warunki akcji</label>

              <b-input-group>

                <b-form-input v-model="warunki" size="lg" ref="warunki" placeholder="np. jeżeli kupisz w promocji otrzymasz rabat 30% lub dodatkowy bonus"></b-form-input>

              </b-input-group>

            </div>

            <br/>

            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>

          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj kampanię
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje kamapnię <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana kampania</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'App12XSellingMachineAI',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        query: "",
        produkt: "",
        miesiac: "",
        warunki: "",
        koniec: "",
        addEmoji: false,
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {
  
      generateFinish: function() {

        if(!this.produkt) return false;
        if(!this.miesiac) return false;
        if(!this.warunki) return false;
        if(!this.koniec) return false;

        
        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Zaplanuj 3 dniową akcję wyprzedażową produktu " + this.produkt + ". Akcja ma się odbyć w miesiącu " + this.miesiac + " dlatego wymyśli powód akcji np. konkretne święto i okazję. Akcja ma polegać na " + this.warunki + " Następnie napisz 3 emaile promujące akcję promocyjną. Pierwszy dzień start akcji i ponaglenie do skorzystania (akcja trwa tylko 3 dni). Dzień drugi przypomnienie o korzyściach ze skorzystania z promocji. Dzień 3 wiadomość w stylu – masz czas tylko do 23.59. Akcja kończy się " + this.koniec + ". E-maile oddziel znakami ------. ";

        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana kampania"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>