<template> 

  <div class="container pt-4">

    <div v-if="!selectedSchema">

      Wybierz styl:
 
      <b-list-group class="mt-3">
        <b-list-group-item :class="{ 'text-primary': (item.type == 'label'), 'pl-3': (item.type !== 'label') }"  v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle" v-if="item.icon">{{ item.icon }}</span> {{ item.name }}
          <small v-if="item.description"><br/>{{ item.description }}</small>
        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
         
          </h2>
          <div v-if="this.selectedSchema.description"><br/>{{ this.selectedSchema.description }}</div>
          <hr/>
          

          <div class="mt-4">
              
      
            <div v-if="this.selectedSchema.fields.includes('WSTAW NAZWĘ PRODUKTU')">

              <label>Wpisz nazwę produktu:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="productName" placeholder="np. tabletki odchudzające"></b-form-input>
                
              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('WSTAW KRÓTKI OPIS')" class="mt-3">

              <label>Krótki opis produktu/usługi:</label>

              <b-input-group>

                <b-form-input v-model="shortDesc" ref="shortDesc" placeholder="np. nasza usługa to zainstalowanie paneli. Wyróżnia nas to, że robimy to szybko i mamy niższe ceny niż konkurencja. Kolor roweru to czerwień. Rower posiada kosz."></b-form-input>
                
              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('WSTAW GRUPĘ DOCELOWĄ')" class="mt-3">

              <label>Wstaw nazwę grupy docelowej:</label>

              <b-input-group>

                <b-form-input v-model="group" ref="group" placeholder="np. mieszkańcy domów jednorodzinnych, księgowe"></b-form-input>
                
              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('WSTAW TON')" class="mt-3">

              <label>Wybierz ton:</label>

              <b-input-group>

                <b-form-select v-model="tone" ref="tone" :options="tones"></b-form-select>
               
              </b-input-group>

            </div>

            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje ofertę dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowana oferta</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppOfferGeneratorAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        occupation: "",
        comment: "",
        industry: "",
        name: "",
        tematyka: "",
        problem: "",
        client: "",
        w_czym_pomagasz: "",
        style: "",
        tone: "",
        pasteText: "",
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        tones: [
           'Beztroski' ,
           'Buntowniczy' ,
           'Charyzmatyczny' ,
           'Dociekliwy' ,
           'Dyplomatyczny' ,
           'Dystansujący się' ,
           'Elegancki' ,
           'Emocjonalny' ,
           'Entuzjastyczny' ,
           'Euforyczny' ,
           'Humorystyczny' ,
           'Inspirujący' ,
           'Ironiczny' ,
           'Konkretny' ,
           'Krytyczny' ,
           'Melancholijny' ,
           'Naukowy' ,
           'Neutralny' ,
           'Obojętny' ,
           'Oficjalny' ,
           'Optymistyczny' ,
           'Oschły' ,
           'Perswazyjny' ,
           'Pesymistyczny' ,
           'Profesjonalny' ,
           'Prosty i zrozumiały' ,
           'Przepraszający' ,
           'Przyjazny' ,
           'Rzeczowy' ,
           'Sarkastyczny' ,
           'Sentymentalny' ,
           'Serdeczny' ,
           'Sprzedażowy' ,
           'Subtelny' ,
           'Tajemniczy' ,
           'Troskliwy' ,
           'Uczuciowy' ,
           'Wspierający' ,
           'Zabawny' ,
           'Zawoalowany' ,
           'Zawzięty' 
        ],
        selectedSchema: null,
        schema: [
          {
            name: "AIDA (Attention, Interest, Desire, Action)",
            description: "Najczęstsza formuła copywriterska. Na początku zwracasz uwagę odbiorców, następnie wzbudzasz zainteresowanie, żeby za chwilę przejść do pożądania. Kończysz tekst wezwaniem do działania.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Wciel się w genialnego copywritera z 10 letnim stażem, napisz rozszerzoną ofertę sprzedażową [SALES COPY] dla [WSTAW NAZWĘ PRODUKTU] celem oferty jest sprzedać jak najwięcej produktów. Oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] . Użyj tonu [WSTAW TON]. Grupą docelową są [WSTAW GRUPĘ DOCELOWĄ]. Wstaw następujące power words [genialny, niesamowity, oburzająco piękny, robiący wrażenie]. Napisz ofertę w oparciu o schemat AIDA, czyli na początku stwórz silny nagłówek w stylu „Jak osiągnąć [WSTAW CEL GRUPY ODBIORCÓW] w mniej niż [WSTAW CZAS OSIĄGNIĘCIA EFEKTÓW]”. Następnie zainteresuj odbiorcę opisując jego problemy. Następnie wzbudź pożądanie wyliczając korzyści z zakupu produktu. Na końcu podaj silne wezwanie do akcji. Pisz w stylu bezpośrednim na TY. Niech tekst będzie łatwy w czytaniu. Używaj prostych słów. Użyj też listy 5 korzyści z zakupu. Pisz w stylu [WSTAW TON]."
          },
          {
            name: "Sales Copy",
            description: "Klasyczna oferta sprzedażowa, która świetnie sprzedaje.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Napisz rozszerzony opis sprzedażowy [sales copy], opisujący unikatowe cechy i korzyści produktu dla [WSTAW NAZWĘ PRODUKTU], oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] które wyróżniają go na tle konkurencji i czynią niezbędnym dla osoby [WSTAW GRUPĘ DOCELOWĄ]. Pisz w tonie [WSTAW TON]."
          },
          {
            name: "PAS (Problem, Agitate, Solve",
            description: "Schemat opiera się na przedstawieniu palącego problemu grupy docelowej, wywołaniu emocji i przedstawieniu rozwiązania jakim jest nasz produkt lub usługa.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Wciel się w genialnego copywritera z 10 letnim stażem, napisz rozszerzoną ofertę sprzedażową [SALES COPY] dla [WSTAW NAZWĘ PRODUKTU] celem oferty jest sprzedać jak najwięcej produktów. Oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] . Użyj tonu [WSTAW TON]. Grupą docelową są [WSTAW GRUPĘ DOCELOWĄ]. Wstaw następujące power words [genialny, niesamowity, oburzająco piękny, robiący wrażenie]. Napisz ofertę w oparciu o schemat PAS (Problem, Agitate, Solve) czyli na początku zidentyfikuj problem grupy docelowej, następni wywołaj emocje związane z tym problemem, następnie zaprezentuj rozwiązanie którym jest nasz produkt. Na początku stwórz silny nagłówek w stylu „Dlaczego tylko nieliczni [GRUPA DOCELOWA] potrafią [CEL JAKI OSIĄGNĄŁ DZIĘKI PRODUKTOWI] w [WPISZ KRÓTKI CZAS]”."
          },
          {
            name: "BAB (Before, After, Bridge)",
            description: "Przedstawienie sytuacji przed rozwiązaniem problemu, po rozwiązaniu problemu oraz mostu (sposobu) łączącego te dwie sytuacje.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Wciel się w genialnego copywritera z 10 letnim stażem, napisz rozszerzoną ofertę sprzedażową [SALES COPY] dla [WSTAW NAZWĘ PRODUKTU] celem oferty jest sprzedać jak najwięcej produktów. Oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] . Użyj tonu [WSTAW TON]. Grupą docelową są [WSTAW GRUPĘ DOCELOWĄ]. Wstaw następujące power words [genialny, niesamowity, oburzająco piękny, robiący wrażenie]. Wstaw następujące power words [ekskluzywny, gwarantowany, zmysłowy, niezapomniany]. Napisz ofertę w oparciu o copywriterski schemat BAB (Before, After, Bridge) – najpierw przedstaw  sytuację przed rozwiązaniem problemu, potem opisz sytuację po rozwiązaniu problemu za pomocą twojego produktu, następnie dodaj mostu (sposobu) łączącego te dwie sytuacje.) Na początku stwórz silny nagłówek w stylu „Jak mieć [WSTAW CEL GRUPY DOCELOWEJ] i [WSTAW DRUGI CEL GRUPY DOCELOWEJ] bez [WSTAW CZYNNOŚĆ, KTÓRA JEST CIĘŻKA I GRUPA DOCELOWA NIE CHCE TEJ CZYNNOŚCI WYKONYWAĆ]”."
          },
          {
            name: "SLAP (Stop, Look, Act, Purchase)",
            description: "Zatrzymanie uwagi odbiorcy, zmuszenie go do zwrócenia uwagi na ofertę, podjęcia działań i finalnie dokonania zakupu.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Wciel się w genialnego copywritera z 10 letnim stażem, napisz rozszerzoną ofertę sprzedażową [SALES COPY] dla [WSTAW NAZWĘ PRODUKTU] celem oferty jest sprzedać jak najwięcej produktów. Oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] . Użyj tonu [WSTAW TON]. Grupą docelową są [WSTAW GRUPĘ DOCELOWĄ]. Użyj następujących power words [niezwykły, bezpieczny, oburzająco dobry, ponadczasowy, unikalny, wzbudzający zazdrość]. Napisz ofertę w oparciu o copywriterski schemat SLAP (Stop, Look, Act, Purchase) – najpierw zatrzymaj uwagę uwagi odbiorcy, zmuś go do zwrócenia uwagi na ofertę następnie przekonaj go do podjęcia działania i finalnie dokonania zakupu. Na początku stwórz silny nagłówek w stylu „Poznaj sekret[WPISZ GRUPĘ DOCELOWĄ], którzy potrafią [WSTAW CEL GRUPY DOCELOWEJ]”."
          },
          {
            name: "ACCA (Awareness, Comprehension, Conviction, Action)",
            description: "Budowanie świadomości problemu, zrozumienie oferty, przekonanie o wartości oferty i wezwanie do działania.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Wciel się w genialnego copywritera z 10 letnim stażem, napisz rozszerzoną ofertę sprzedażową [SALES COPY] dla [WSTAW NAZWĘ PRODUKTU] celem oferty jest sprzedać jak najwięcej produktów. Oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] . Użyj tonu [WSTAW TON]. Grupą docelową są [WSTAW GRUPĘ DOCELOWĄ]. Użyj następujących power words [Wyjątkowy, Natychmiast, Niezawodny, Gwarantowany, Rewolucyjny]. Napisz ofertę w oparciu o copywriterski schemat Schemat ACCA (Awareness, Comprehension, Conviction, Action) – najpierw zbuduj w odbiorcy świadomości problemu, następnie spowoduj, że zrozumie on ofertę, następnie przekonaj odbiorcę o wartości oferty i napisz silne wezwanie do działania. Zacznij tekst od silnego nagłówka w stylu „Poznaj sekret [WSTAW GRUPĘ DOECELOWĄ, którzy potrafią [WSTAW CEL, KTÓRY OSIĄGAJĄ PRZEDSTAWICIELE GRUPY DOCELOWEJ”."
          },
          {
            name: "QUEST (Qualify, Understand, Educate, Stimulate, Transition)",
            description: "Kwalifikowanie odbiorcy, zrozumienie jego potrzeb, edukowanie go, stymulowanie emocji i przekierowanie do podjęcia decyzji.",
            icon: "notes",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW KRÓTKI OPIS", "WSTAW GRUPĘ DOCELOWĄ", "WSTAW TON"],
            command: "Wciel się w genialnego copywritera z 10 letnim stażem, napisz rozszerzoną ofertę sprzedażową [SALES COPY] dla [WSTAW NAZWĘ PRODUKTU] celem oferty jest sprzedać jak najwięcej produktów. Oto opis produktu żeby łatwiej było Ci napisać ofertę [WSTAW KRÓTKI OPIS] . Użyj tonu [WSTAW TON]. Grupą docelową są [WSTAW GRUPĘ DOCELOWĄ]. Użyj następujących power words [odważny, Przełomowy, Bezcenny, oszczędny, Ekskluzywny, Niezastąpiony]. Napisz ofertę w oparciu o copywriterski schemat QUEST (Qualify, Understand, Educate, Stimulate, Transition) – Najpierw zrób kwalifikowanie odbiorcy,  następnie opisz zrozumienie jego potrzeb, Następnie edukuj go w danej tematyce oferty, następnie wpłyń na jego emocje na końcu zrób wezwanie do działania. Zacznij tekst od silnego nagłówka w stylu „Dlaczego tylko [WSTAW GRUPĘ DOCELOWĄ] mają [CEL KTÓRY UDAŁO SIĘ IM OSIĄGNĄ]?”."
          }    
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
  
        query = this.selectedSchema.command;

    
        if(this.$refs.productName)
          query = query.replaceAll('[WSTAW NAZWĘ PRODUKTU]', this.$refs.productName.value)

        if(this.$refs.shortDesc)
          query = query.replaceAll('[WSTAW KRÓTKI OPIS]', this.$refs.shortDesc.value)

        if(this.$refs.group)
          query = query.replaceAll('[WSTAW GRUPĘ DOCELOWĄ]', this.$refs.group.value)
 
        if(this.$refs.tone)
          query = query.replaceAll('[WSTAW TON]', this.tone)
 
          
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }
 
   

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowana oferta"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Oferta została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>