<template> 

  <div class="container pt-4">

    <div v-if="!selectedSchema">

      Wybierz styl:
 
      <b-list-group class="mt-3">
        <b-list-group-item v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle">article</span> {{ item.name }}

        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
          
          </h2>


          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz nazwę produktu:</label>

              <b-input-group>

                <b-form-input v-model="productName" ref="productName" placeholder="np. tabletki odchudzające" v-on:keyup.enter="generateFinish()"></b-form-input>
                
              </b-input-group>

            </div>
 
            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
            <b-form-checkbox v-model="addCallToFollow" value="true">Dodaj wezwanie do obserwowania</b-form-checkbox>
            <b-form-checkbox v-model="addHashtags" value="true">Dodaj hashtagi</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje Twój Post <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppAiSellingMachine',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        productName: "",
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
        schema: [
          {
            name: "Styl AIDA",
            query:  "Napisz opis dla produktu [WSTAW PRODUKT] Według schematu 1. Nagłówek copywriterski zwracających uwagę 2. Opisanie problemu 3. Opisanie rozwiązania jakim jest produkt [WSTAW PRODUKT] 4. Wezwanie do akcji, żeby osoba kupiła produkt [WSTAW PRODUKT]."
          },
          {
            name: "Styl 5 korzyści",
            query: "Napisz sprzedażowy opis dla produktu [WSTAW PRODUKT] Według schematu 1. Nagłówek copywriterski, który zawiera pytanie, dlaczego, jak i przyciąga uwagę 2. Opisz problem 3. Opisanie rozwiązania jakim jest produkt [WSTAW PRODUKT] 4. Napisz 5 korzyści z zakupu [WSTAW PRODUKT]  5. Wezwanie do akcji, żeby osoba kupiła produkt [WSTAW PRODUKT]."
          },
          {
            name: "Opinia. AIDA. 5 Korzyści",
            query: "Napisz sprzedażowy opis dla produktu [WSTAW PRODUKT] Według schematu 1. Opinia zadowolonego klienta 2. Nagłówek copywriterski, który zawiera pytanie, dlaczego, jak i przyciąga uwagę 3. Opisz problem 4. Opisz rozwiązania jakim jest produkt [WSTAW PRODUKT] 5. Napisz 5 korzyści z zakupu [WSTAW PRODUKT]  6. Wezwanie do akcji, żeby osoba kupiła produkt [WSTAW PRODUKT]."
          },
          {
            name: "Sprzedaż Storytellingowa",
            query: "Napisz sprzedażowy opis dla produktu [WSTAW PRODUKT] Według schematu 1. Historia jakie klient miał problemy zanim kupił [WSTAW PRODUKT]   2. Jak klient rozwiązał problem dzięki [WSTAW PRODUKT] 3. Napisz 5 korzyści z zakupu [WSTAW PRODUKT] 4. Wezwanie do akcji, żeby osoba kupiła produkt [WSTAW PRODUKT]."
          },
          {
            name: "Opis dla zmysłów",
            query: "Napisz sprzedażowy opis produktu [WSTAW PRODUKT] w stylu sprzedaży telewizyjnej w którym odnosisz się do wszystkich zmysłów. Na końcu napisz silne wezwanie do akcji."
          },
          {
            name: "Porażki - Sukces",
            query: "Napisz sprzedażowy opis produktu [WSTAW PRODUKT], w którym opisujesz dwie próby zakończone porażką aż klientka trafiła wreszcie na Twój produkt. Zwróć uwagę na to, że straciła dużo pieniędzy aż trafiła na produkt [WSTAW PRODUKT]. Napisz 5 korzyści, każdy od zielonego emoji.  Na końcu dodaj wezwanie do akcji."
          },
          {
            name: "Tekst w stylu 'śmiali się, gdy...'",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od przerobionego nagłówka „śmiali się, gdy płaciłem za kurs gry na pianinie, ale gdy zacząłem grać to…” Napisz w tym stylu nagłówek dla produktu [WSTAW PRODUKT]. Następnie napisz opis produktu stawiając na to czym różni się od produktów konkurencji. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Tekst z nagłówkiem 'Dlaczego'",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od przerobionego nagłówka „dlaczego tylko nieliczni inwestorzy zarabiają na giełdzie?” Napisz w tym stylu nagłówek dla produktu [WSTAW PRODUKT]. Następnie napisz opis produktu stawiając na to czym różni się od produktów konkurencji. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Tekst w stylu 'Daj mi 3 minuty...'",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od przerobionego nagłówka „Daj mi 3 minuty a pokażę Ci jak zarabiać na giełdzie?” Napisz w tym stylu nagłówek dla produktu [WSTAW PRODUKT]. Następnie napisz opis produktu stawiając na to czym różni się od produktów konkurencji. Wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Tekst w stylu 'A co, jeżeli powiem Ci, że od lat źle...'",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od przerobionego nagłówka „A co, jeżeli powiem Ci, że od lat źle inwestujesz na giełdzie?” Napisz w tym stylu nagłówek dla produktu [WSTAW PRODUKT]. Następnie napisz opis produktu stawiając na to czym różni się od produktów konkurencji. Wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Presja czasu",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od presji czasu związanej z tym, że nie długo kończy się promocja. Następnie napisz opis produktu stawiając na to czym różni się od produktów konkurencji. Wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Historia klienta/klientki i rozbicie obiekcji.",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij od historii klientki/klienta którzy mieli problem, bo nie znali waszego produktu. Opisz cierpienie i problemy klientów, bo nie znali tego rozwiązania. Następnie opisz ich wahanie i to, że nie wierzyli, że Wasz produkt w czymś pomoże. Byli sceptyczni, jednak, gdy zaczęli korzystać z [WSTAW PRODUKT] to chcieli polecać go wszystkim. Następnie napisz opis produktu stawiając na to czym różni się od produktów konkurencji. Wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "5 Opinii. 5 Korzyści.",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij od 5 pozytywnych opinii gdzie dodasz imię osoby, która napisała opinie. Dodaj też po 5 gwiazdek emoji. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Tekst w stylu 'Czy wiedziałeś że...''",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od ciekawostki w stylu „Czy wiedziełeś, że…” Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Łzawa historia",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od łzawej, zmuszającej do płaczu historii klientki. Potem zrób zwrot na zasadzie – wcale nie musisz tak cierpieć. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Palący problem",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od palącego problemu grupy docelowej, która korzysta z [WSTAW PRODUKT]. Następnie napisz, że na szczęście nasz produkt rozwiązuje ten problem. Napisz 3 opinie o produkcie z podaniem imion. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Dowód społeczny",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od palącego problemu grupy docelowej, która korzysta z [WSTAW PRODUKT]. Następnie napisz, że na szczęście nasz produkt rozwiązuje ten problem. Napisz jak wiele osób skorzystało już z tego produktu. Napisz to tak żeby ludzie mieli wrażenie, że tysiące osób korzysta z tego rozwiązania. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "To nie jest dla wszystkich",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od tego, że nie jesteście najtańsi i nie pracujecie z każdym. Że jest to produkt lub usługa tylko dla tych co doceniają jakość. Napisz jak wiele osób skorzystało już z tego produktu. Napisz to tak żeby ludzie mieli wrażenie, że tysiące osób korzysta z tego rozwiązania. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Za co płacisz",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od palącego problemu grupy docelowej, która korzysta z [WSTAW PRODUKT]. Następnie napisz, że na szczęście nasz produkt rozwiązuje ten problem. Napisz w 3 punktach za co konkretnie płaci dany klient. Napisz to tak żeby ludzie mieli wrażenie, że tysiące osób korzysta z tego rozwiązania. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Rozbicie obiekcji – za drogo",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij tekst od palącego problemu grupy docelowej, która korzysta z [WSTAW PRODUKT]. Następnie napisz, że na szczęście nasz produkt rozwiązuje ten problem. Rozbij obiekcje klientów, że [WSTAW PRODUKT] jest za drogi. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Głęboka potrzeba",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij od opisani najgłębszych potrzeb klienta i przekonaj go, że ten produkt realizuje te głębokie potrzeby.Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          },
          {
            name: "Prestiż",
            query: "Napisz tekst sprzedażowy dla [WSTAW PRODUKT]. Zacznij od opisania w jaki sposób twój produkt spowoduje, że inni ludzie zaczną bardziej szanować klientów. Opis produkt tak, żeby dawał wrażenie, że doda nam prestiżu. Opisz go tak, że widać, że jest drogi i nie dla wszystkich. Następnie wymień 5 korzyści z zakupu [WSTAW PRODUKT]. Na koniec silne wezwanie do akcji i post scriptum."
          }
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        if(!this.productName) return false;
         
        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
 
         
        query = this.selectedSchema.query;

         
        if(this.$refs.productName)
          query = query.replaceAll('[WSTAW PRODUKT]', this.$refs.productName.value)

  
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

        if(this.addCallToFollow) {

          query = query + "Dodaj wezwanie do obserwowania."

        }

        if(this.addHashtags) {

          query = query + "Dodaj hashtagi na końcu."

        }
 
       

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany tekst"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>