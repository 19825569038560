<template> 
 <div class="bg-blue-dark">
  <b-navbar toggleable="lg" class="container bg-blue-dark" type="dark">
    <b-navbar-brand :to="'/dashboard'"><img src="https://funnelstar.io/img/logo-gb-b.png" class="logo"/></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            Przeglądaj
          </template>
          <b-dropdown-item :to="'/courses'">Kursy</b-dropdown-item>
          <b-dropdown-item :to="'/apps'">Aplikacje</b-dropdown-item>
          <b-dropdown-item :to="'/services'">Usługi</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- <b-navbok
      ar-nav>
        <b-nav-item-dropdown right>
          <template #button-content>
            Usługi
          </template>
          <b-dropdown-item :href="'https://app.funnelstar.io/services/seo-links'">Katalogowanie</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav> -->


      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-navbar-nav>
          <!-- <b-nav-item :to="'/achievements'"><span class="">Osiągnięcia</span></b-nav-item> -->
          <b-nav-item :to="'/my-products'"><span class="text-blue-light">Moje produkty</span></b-nav-item>
        </b-navbar-nav>
 
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            Konto
          </template>
          <b-dropdown-item :href="'https://id.funnelstar.io/dashboard'">Ustawienia</b-dropdown-item>
          <b-dropdown-item :href="'https://id.funnelstar.io/dashboard/personal'">Profil</b-dropdown-item>
          <b-dropdown-item :href="'https://id.funnelstar.io/dashboard/payments'">Płatności</b-dropdown-item>
          <b-dropdown-item :href="'https://docs.funnelstar.io/formularz-kontaktowy'">Kontakt</b-dropdown-item>
          <b-dropdown-item :href="'https://id.funnelstar.io/logout'">Wyloguj</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  props: {
     
  }
}
</script>

 