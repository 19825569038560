<template> 

  <div>

    <Loader text="Ładowanie Twoich usług" v-if="!user.id" />
  
    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">Moje usługi</h2>
  
      <div class="pt-3">

        <b-card title="Katalogowanie" class="mb-2 mt-4" v-if="user.permissions.includes('seo.links')">
          <b-card-text>
            Przejdź do raportu z usługi katalogowania.
          </b-card-text>
          <b-button :href="'https://app.funnelstar.io/services/seo-links'" variant="light">Idź do raportu <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">arrow_right_alt</span></b-button>
        </b-card>

      </div>

    </div>

  </div>

</template>

<script>

  import Loader from '../../elements/Loader';

  export default {

    name: 'Services',

    components: {
      Loader
    },

    data() {

      return {
        user: {
          id: null,
          permissions: []
        }
      }

    },

    created() {
      
      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data 

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data.userId) {

          this.user.id = data.userId
          this.user.permissions = data.permissions

        }

      });

      // Set breadcumbs
      this.$root.$emit('system.breadcumber.set', [])

    },

  }
  
</script>

 