<template> 

  <div class="container pt-4">

    <div v-if="!selectedSchema">

      Wybierz moduł:
 
      <b-list-group class="mt-3">
        <b-list-group-item :class="{ 'text-primary': (item.type == 'label'), 'pl-5': (item.type !== 'label') }"  v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle" v-if="item.icon">{{ item.icon }}</span> {{ item.name }}

        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
          
          </h2>


          <div class="mt-4">
              
            <div v-if="this.selectedSchema.fields.includes('WSTAW TEMAT')">

              <label>Wpisz tematykę:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="topic" placeholder="np. odchudzanie"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW NAZWĘ PRODUKTU')">

              <label>Wpisz nazwę produktu:</label>

              <b-input-group>

                <b-form-input v-model="productName" ref="productName" placeholder="np. tabletki odchudzające"></b-form-input>
                
              </b-input-group>

            </div>
        
            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW IMIĘ')">

              <label>Twoje imię:</label>

              <b-input-group>

                <b-form-input v-model="name" ref="name" placeholder="np. Adam"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW TEMATYKĘ PROFILU')">

              <label>Tematyka profilu:</label>

              <b-input-group>

                <b-form-input v-model="tematyka" ref="tematyka" placeholder="np. Fitness, Dietetyka"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('KTO JEST TWOIM KLIENTEM')">

              <label>Kto jest Twoim klientem:</label>

              <b-input-group>

                <b-form-input v-model="client" ref="client" placeholder="np. osoby z nadwagą, przedsiębiorcy"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW PROBLEM KLIENTÓW')">

              <label>Wstaw problem klientów:</label>

              <b-input-group>

                <b-form-input v-model="problem" ref="problem" placeholder="np. brak pieniędzy, problemy finansowe, brak klientów, brak efektów diety"></b-form-input>
                
              </b-input-group>

            </div>
             
            <div class="mt-3" v-if="this.selectedSchema.fields.includes('W CZYM POMAGASZ GRUPIE DOCELOWEJ')">

              <label>W czym pomagasz swojej grupie docelowej:</label>

              <b-input-group>

                <b-form-input v-model="w_czym_pomagasz" ref="w_czym_pomagasz" placeholder="np. schudnąć, zwiększyć zyski"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW OPIS ZAWODU')">

              <label>Wpisz opis zawodu:</label>

              <b-input-group>

                <b-form-input v-model="occupation" ref="occupation" placeholder="np. marketingowiec"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW BRANŻĘ')">

              <label>Wpisz branżę:</label>

              <b-input-group>

                <b-form-input v-model="industry" ref="industry" placeholder="np. Social Media"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.fields.includes('WSTAW KOMENTARZ')">

              <label>Wpisz komentarz:</label>

              <b-input-group>

                <b-form-input v-model="comment" ref="comment" placeholder="Wpisz tu komentarz"></b-form-input>
                
              </b-input-group>

            </div>

            <div class="mt-3" v-if="this.selectedSchema.styles">

              <label>Wybierz styl:</label>

              <b-input-group>

                <b-form-select v-model="style" ref="style" :options="this.selectedSchema.styles"></b-form-select>
                
              </b-input-group>

            </div>
            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
            <b-form-checkbox v-model="addCallToFollow" value="true">Dodaj wezwanie do obserwowania</b-form-checkbox>
            <b-form-checkbox v-model="addHashtags" value="true">Dodaj hashtagi</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje tekst dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppAiPostCreator',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        occupation: "",
        comment: "",
        industry: "",
        name: "",
        tematyka: "",
        problem: "",
        client: "",
        w_czym_pomagasz: "",
        style: "",
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
        schema: [
          {
              name: "Facebook",
              type: "label"
          },
          {
            name: "Post",
            icon: "post_add",
            styles: ["neutralny","humorystyczny", "poważny", "ekspercki", "krótki", "długi", "na luzie", "profesjonalny", "budujący ciekawość", "czy wiedziałeś, że", "poetycki", "zawierający storytelling", "neutralizujący obiekcje"],
            fields: ["WSTAW TEMAT", "WSTAW NAZWĘ PRODUKTU"],
            items: [
              { style: "neutralny", value: "Napisz post na Facebooka o tematyce: [WSTAW TEMAT]." },
              { style: "humorystyczny", value: "Napisz humorystyczny post na Facebooka o tematyce: [WSTAW TEMAT]." },
              { style: "poważny", value: "Napisz poważny post na Facebooka o tematyce: [WSTAW TEMAT]." },
              { style: "ekspercki", value: "Napisz ekspercki post na Facebooka o tematyce: [WSTAW TEMAT]." },
              { style: "krótki", value: "Napisz krótki post na Facebooka o tematyce: [WSTAW TEMAT]." },
              { style: "długi", value: "Napisz długi post na Facebooka o tematyce: [WSTAW TEMAT]." },
              { style: "na luzie", value: "Napisz luźny post na Facebooku o tematyce: [WSTAW TEMAT]." },
              { style: "profesjonalny", value: "Napisz profesjonalny post na Facebooku o tematyce: [WSTAW TEMAT]." },
              { style: "budujący ciekawość", value: "Napisz post na Facebooku, który zbuduje ciekawość o tematyce: [WSTAW TEMAT]." },
              { style: "czy wiedziałeś, że", value: "Napisz post na Facebooka o tematyce [WSTAW TEMAT] zaczynający się od słów: Czy wiedziałeś, że." },
              { style: "poetycki", value: "Napisz post na Facebooka w stylu poetyckim o tematyce [WSTAW TEMAT]." },
              { style: "zawierający storytelling", value: "Napisz post na Facebooka zawierający historię i storytelling o tematyce [WSTAW TEMAT]." },
              { style: "neutralizujący obiekcje", value: "Napisz post na Facebooka rozbijający obiekcje przed zakupem [WSTAW NAZWĘ PRODUKTU]." }             
            ]
          },
          {
            name: "Post motywacyjny",
            icon: "post_add",
            fields: ["WSTAW BRANŻĘ"],
            items: [ 
              "Wymyśl mi słowa inspiracji, lub cytat motywacyjny dla branży: [WSTAW BRANŻĘ]."
            ]
          },
          {
            name: "Post głosowanie",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [ 
              "Wymyśl i napisz post, który będzie opierał się na głosowaniu za pomocą reakcji serduszko lub kciuk w górę na jakąś opcję a lub b. Wymyśl te opcje i napisz post dla tematu: [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post storytelling 'Pogoda'",
            icon: "post_add",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM KLIENTÓW"],
            items: [ 
              "Napisz mi historyjkę według schematu. Na początku bohater ma problemy z [WSTAW PROBLEM KLIENTÓW]. Zacznij historyjkę od konkretnej godziny, opisu pogody i odnieś się do wszystkich zmysłów jak wzrok, słuch i węch. Następnie opisz starania bohatera z osiągnięciem sukcesu w [WSTAW PROBLEM KLIENTÓW]. Opisz jego porażki. Nic mu nie wychodziło. Dodaj dramaturgii. Wreszcie trafia na rozwiązanie, czyli produkt lub usługę [WSTAW NAZWĘ PRODUKTU]. Ten produkt lub usługa zmienia jego życie na lepsze i rozwiązuje jego problemy. Na końcu poleć [WSTAW NAZWĘ PRODUKTU] jako coś co rozwiązuje problem."
            ]
          },
          {
            name: "Post storytelling 'Poranek'",
            icon: "post_add",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM KLIENTÓW"],
            items: [ 
              "Napisz mi historyjkę według schematu. Na początku bohater ma problemy z [WSTAW PROBLEM KLIENTÓW]. Zacznij historyjkę od konkretnej godziny, opisu jakiegoś dramatycznego miejsca, tego jak pije kawę lub herbatę i jest bliski załamania,opisz jakie myśli go męczą, odnieś się do wszystkich zmysłów jak czucie, wzrok, słuch i węch. Następnie opisz starania bohatera z osiągnięciem sukcesu w [WSTAW PROBLEM KLIENTÓW]. Opisz jego porażki. Nic mu nie wychodziło. Dodaj dramaturgii. Wreszcie trafia na rozwiązanie, czyli produkt lub usługę [WSTAW NAZWĘ PRODUKTU]. Ten produkt lub usługa zmienia jego życie na lepsze i rozwiązuje jego problemy. Na końcu poleć [WSTAW NAZWĘ PRODUKTU] jako coś co rozwiązuje problem."
            ]
          },
          {
            name: "Post storytelling 'Kłótnia'",
            icon: "post_add",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM KLIENTÓW"],
            items: [ 
              "Napisz mi historyjkę według schematu. Na początku bohater ma problemy z [WSTAW PROBLEM KLIENTÓW] Zacznij historyjkę od konkretnej godziny, opisu otoczenia, opisu kłótni z bliską osobą np. mąż, rodzice z powodu [WSTAW PROBLEM KLIENTÓW], opisz jakie myśli go męczą, odnieś się do wszystkich zmysłów jak czucie, wzrok, słuch i węch. Następnie opisz starania bohatera z osiągnięciem sukcesu w [WSTAW PROBLEM KLIENTÓW]. Opisz jego porażki. Nic mu nie wychodziło. Dodaj dramaturgii. Wreszcie trafia na rozwiązanie, czyli produkt lub usługę [WSTAW NAZWĘ PRODUKTU]. Ten produkt lub usługa zmienia jego życie na lepsze i rozwiązuje jego problemy. Na końcu poleć [WSTAW NAZWĘ PRODUKTU] jako coś co rozwiązuje problem."
            ]
          },
          {
            name: "ADS - Reklama",
            icon: "featured_video",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            styles: ["normalny", "dluższy"],
            items: [
              { style: "normalny", value: "Napisz reklamę na Facebooka dla produktu [WSTAW NAZWĘ PRODUKTU]." },
              { style: "dluższy", value: "Napisz dłuższą reklamę na Facebooka dla produktu [WSTAW NAZWĘ PRODUKTU]." }
            ]
          },
          {
            name: "Live Pomysły",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 5 pomysłów na wydarzenie live na Facebooku w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Live Agenda",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz w 5 punktach program live na Facebooku w tematyce [WSTAW TEMAT]."
            ]
          },
          {
              name: "Instagram",
              type: "label"
          },
          {
            name: "Bio",
            icon: "tag",
            fields: ["WSTAW IMIĘ", "WSTAW TEMATYKĘ PROFILU", "KTO JEST TWOIM KLIENTEM", "W CZYM POMAGASZ GRUPIE DOCELOWEJ"],
            items: [
              "Napisz mi bio na instagram nie dłuższe niż 150 znaków ze spacją. Nazywam się [WSTAW IMIĘ] zajmuję się [WSTAW TEMATYKĘ PROFILU] pomagam [KTO JEST TWOIM KLIENTEM] w [W CZYM POMAGASZ GRUPIE DOCELOWEJ]. Zakończ wezwaniem do obserwowania. Nie dodawaj hashtagów i pamiętaj, żeby zmieścić się w 150 znakach ze spacją."
            ]
          },
          {
            name: "Post",
            icon: "post_add",
            styles: ["neutralny","humorystyczny", "poważny", "ekspercki", "krótki", "długi", "na luzie", "profesjonalny", "budujący ciekawość", "czy wiedziałeś, że", "poetycki", "zawierający storytelling", "neutralizujący obiekcje"],
            fields: ["WSTAW TEMAT", "WSTAW NAZWĘ PRODUKTU"],
            items: [
              { style: "neutralny", value: "Napisz post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "humorystyczny", value: "Napisz humorystyczny post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "poważny", value: "Napisz poważny post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "ekspercki", value: "Napisz ekspercki post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "krótki", value: "Napisz krótki post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "długi", value: "Napisz długi post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "na luzie", value: "Napisz luźny post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "profesjonalny", value: "Napisz profesjonalny post na Instagram o tematyce: [WSTAW TEMAT]." },
              { style: "budujący ciekawość", value: "Napisz post na Instagram, który zbuduje ciekawość o tematyce: [WSTAW TEMAT]." },
              { style: "czy wiedziałeś, że", value: "Napisz post na Instagram o tematyce [WSTAW TEMAT] zaczynający się od słów: Czy wiedziałeś, że." },
              { style: "poetycki", value: "Napisz post na Instagram w stylu poetyckim o tematyce [WSTAW TEMAT]." },
              { style: "zawierający storytelling", value: "Napisz post na Instagram zawierający historię i storytelling o tematyce [WSTAW TEMAT]." },
              { style: "neutralizujący obiekcje", value: "Napisz post na Instagram rozbijający obiekcje przed zakupem [WSTAW NAZWĘ PRODUKTU]." }
            ]
          },
          {
            name: "Post storytelling 'Pogoda'",
            icon: "post_add",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM KLIENTÓW"],
            items: [ 
              "Napisz mi historyjkę według schematu. Na początku bohater ma problemy z [WSTAW PROBLEM KLIENTÓW]. Zacznij historyjkę od konkretnej godziny, opisu pogody i odnieś się do wszystkich zmysłów jak wzrok, słuch i węch. Następnie opisz starania bohatera z osiągnięciem sukcesu w [WSTAW PROBLEM KLIENTÓW]. Opisz jego porażki. Nic mu nie wychodziło. Dodaj dramaturgii. Wreszcie trafia na rozwiązanie, czyli produkt lub usługę [WSTAW NAZWĘ PRODUKTU]. Ten produkt lub usługa zmienia jego życie na lepsze i rozwiązuje jego problemy. Na końcu poleć [WSTAW NAZWĘ PRODUKTU] jako coś co rozwiązuje problem."
            ]
          },
          {
            name: "Post storytelling 'Poranek'",
            icon: "post_add",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM KLIENTÓW"],
            items: [ 
              "Napisz mi historyjkę według schematu. Na początku bohater ma problemy z [WSTAW PROBLEM KLIENTÓW]. Zacznij historyjkę od konkretnej godziny, opisu jakiegoś dramatycznego miejsca, tego jak pije kawę lub herbatę i jest bliski załamania,opisz jakie myśli go męczą, odnieś się do wszystkich zmysłów jak czucie, wzrok, słuch i węch. Następnie opisz starania bohatera z osiągnięciem sukcesu w [WSTAW PROBLEM KLIENTÓW]. Opisz jego porażki. Nic mu nie wychodziło. Dodaj dramaturgii. Wreszcie trafia na rozwiązanie, czyli produkt lub usługę [WSTAW NAZWĘ PRODUKTU]. Ten produkt lub usługa zmienia jego życie na lepsze i rozwiązuje jego problemy. Na końcu poleć [WSTAW NAZWĘ PRODUKTU] jako coś co rozwiązuje problem."
            ]
          },
          {
            name: "Post storytelling 'Kłótnia'",
            icon: "post_add",
            fields: ["WSTAW NAZWĘ PRODUKTU", "WSTAW PROBLEM KLIENTÓW"],
            items: [ 
              "Napisz mi historyjkę według schematu. Na początku bohater ma problemy z [WSTAW PROBLEM KLIENTÓW] Zacznij historyjkę od konkretnej godziny, opisu otoczenia, opisu kłótni z bliską osobą np. mąż, rodzice z powodu [WSTAW PROBLEM KLIENTÓW], opisz jakie myśli go męczą, odnieś się do wszystkich zmysłów jak czucie, wzrok, słuch i węch. Następnie opisz starania bohatera z osiągnięciem sukcesu w [WSTAW PROBLEM KLIENTÓW]. Opisz jego porażki. Nic mu nie wychodziło. Dodaj dramaturgii. Wreszcie trafia na rozwiązanie, czyli produkt lub usługę [WSTAW NAZWĘ PRODUKTU]. Ten produkt lub usługa zmienia jego życie na lepsze i rozwiązuje jego problemy. Na końcu poleć [WSTAW NAZWĘ PRODUKTU] jako coś co rozwiązuje problem."
            ]
          },
          {
            name: "Reels - Pomysły",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 5 pomysłów na Instagram Reels w temacie [WSTAW TEMAT]."
            ]
          },
          {
            name: "Reels - Skrypt",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            styles: ["neutralny", "zabawny", "wzbudzający ciekawość"],
            items: [
              { style: "neutralny", value: "Napisz treść rolki na Instagram w tematyce [WSTAW TEMAT]." },
              { style: "zabawny", value: "Napisz treść zabawnej rolki na Instagram w tematyce [WSTAW TEMAT]. Pamiętaj, żeby na końcu było wezwanie do obserwowania profilu." },
              { style: "wzbudzający ciekawość", value: "Napisz skrypt filmiku na TikToka w tematyce [WSTAW TEMAT] według schematu Wstęp: nagłówek w stylu '3 rzeczy, których nie wiedziałeś o laptopie'. Część merytoryczna Wymień 3 rzeczy dotyczące [WSTAW TEMAT] Po pierwsze. Po drugie. Po trzecie. Omów każdy element. Na Końcu przekonaj do napisania komentarza na temat [WSTAW TEMAT]." }
            ]
          },
          {
            name: "Reels - Opis",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz krótki opis rolki na instagram w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Hashtagi",
            icon: "tag",
            fields: ["WSTAW TEMAT"],
            items: [
              "Wypisz 30 hashtagów dla postu na Instagramie o tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Live",
            icon: "live_tv",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 5 pomysłów na wydarzenie live na Instagramie w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Live Agenda",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz w 5 punktach program live na Instagramie w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Stories Ideas",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT", "WSTAW NAZWĘ PRODUKTU"],
            styles: ["neutralny", "ekspercki", "zabawny", "osobisty", "sprzedażowy"],
            items: [
              { style: "neutralny", value: "Napisz 5 pomysłów na Instagram Stories w tematyce [WSTAW TEMAT]." },
              { style: "ekspercki", value: "Napisz 5 pomysłów na eksperckie Instagram Stories w tematyce [WSTAW TEMAT]." },
              { style: "zabawny", value: "Napisz 5 pomysłów na zabawne Instagram Stories w tematyce [WSTAW TEMAT]." },
              { style: "osobisty", value: "Napisz 5 pomysłów na osobiste Instagram Stories w tematyce [WSTAW TEMAT]." },
              { style: "sprzedażowy", value: "Napisz 5 pomysłów na  sprzedażowe Instagram Stories w którym sprzedajemy [WSTAW NAZWĘ PRODUKTU]." }
            ]
          },
          {
            name: "Post karuzelowy - pomysły",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi 10 pomysłów na posty karuzelowe do Instagrama dla [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post karuzelowy - kafelki o produkcie",
            icon: "list_alt",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            items: [
              "Napisz mi treść dla postu karuzelowego na Instagram, który będzie zawierał 7 kafelków. Post w stylu '7 nowych funkcji Instagrama'. Post ma dotyczyć tematu: [WSTAW NAZWĘ PRODUKTU]."
            ]
          },
          {
            name: "Post karuzelowy - kafelki o usłudze",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi treść dla postu karuzelowego na Instagram, który będzie zawierał 7 kafelków. Post w stylu '7 rzeczy których nie wiedziałeś na temat farbowania włosów'. Post ma dotyczyć tematu: [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post karuzelowy - kafelki o książce",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi treść dla postu karuzelowego na Instagram, który będzie zawierał 7 kafelków. Post w stylu '7 rzeczy, których nie wiedziałeś na temat jelit'. Post ma dotyczyć tematu: [WSTAW TEMAT]. Na końcu poleć moją książkę nt. [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post karuzelowy - kafelki o kursie",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi treść dla postu karuzelowego na Instagram, który będzie zawierał 7 kafelków. Post w stylu '7 rzeczy, których nie wiedziałeś na temat jelit'. Post ma dotyczyć tematu: [WSTAW TEMAT]. Na końcu poleć moją książkę nt. [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post karuzelowy - kafelki o MLM",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi treść dla postu karuzelowego na Instagram, który będzie zawierał 7 kafelków. Post w stylu '7 rzeczy, których nie wiedziałeś na temat network marketingu'. Post ma dotyczyć tematu: [WSTAW TEMAT] Na końcu poleć moją książkę nt. [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post karuzelowy - opis",
            icon: "list_alt",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi post na Instagram do postu karuzelowego. Tematyka postu to: [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post karuzelowy - hashtagi",
            icon: "tag",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz mi 30 hashtagów do postu karuzelowego. Tematyka postu to: [WSTAW TEMAT]."
            ]
          },
          {
              name: "TikTok",
              type: "label"
          },
          {
            name: "Ideas",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT", "WSTAW NAZWĘ PRODUKTU"],
            styles: ["neutralny", "ekspercki", "zabawny", "osobisty", "sprzedażowy"],
            items: [
              { style: "neutralny", value: "Napisz 5 pomysłów na TikToki w tematyce [WSTAW TEMAT]." },
              { style: "ekspercki", value: "Napisz 5 pomysłów na eksperckie TikToki w tematyce [WSTAW TEMAT]." },
              { style: "zabawny", value: "Napisz 5 pomysłów na zabawne TikToki w tematyce [WSTAW TEMAT]." },
              { style: "osobisty", value: "Napisz 5 pomysłów na osobiste TikToki w tematyce [WSTAW TEMAT]." },
              { style: "sprzedażowy", value: "Napisz 5 pomysłów na sprzedażowe TikToki w którym sprzedajemy [WSTAW NAZWĘ PRODUKTU]." }
            ]
          },
          {
            name: "Opis",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz krótki opis TikToka w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "ADS - Reklama",
            icon: "featured_video",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            items: [
              "Napisz skrypt krótkiej reklamy wideo na TikToka w której sprzedajesz [WSTAW NAZWĘ PRODUKTU]."
            ]
          },
          {
            name: "Skrypt",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz skrypt filmiku na TikToka w tematyce [WSTAW TEMAT] według schematu Wstęp: nagłówek w stylu '3 rzeczy, których nie wiedziałeś o laptopie'. Część merytoryczna: Wymień 3 rzeczy dotyczące [WSTAW TEMAT] Po pierwsze. Po drugie. Po trzecie. Omów każdy element. Na końcu przekonaj do napisania komentarza na temat [WSTAW TEMAT]."
            ]
          },
          {
              name: "LinkedIn",
              type: "label"
          },
          {
            name: "Artykuł",
            icon: "post_add",
            styles: ["profesjonalny", "ekspercki", "luźniejszy", "opisujący karierę", "opisujący plany przyszłość"],
            fields: ["WSTAW TEMAT", "WSTAW OPIS ZAWODU", "WSTAW BRANŻĘ"],
            items: [
              { style: "profesjonalny", value: "Napisz profesjonalny artykuł na LinkedIn na temat [WSTAW TEMAT]." },
              { style: "ekspercki", value: "Napisz ekspercki artykuł na LinkedIn na temat [WSTAW TEMAT]." },
              { style: "luźniejszy", value: "Napisz luźny artykuł na LinkedIn na temat [WSTAW TEMAT]." },
              { style: "opisujący karierę", value: "Napisz artykuł na LinkedIn opisujący karierę [WSTAW OPIS ZAWODU]." },
              { style: "opisujący plany przyszłość", value: "Napisz ekspercki artykuł na LinkedIn na temat planów na przyszłość związanych z karierą w branży [WSTAW BRANŻĘ]." }
            ]
          },
          {
              name: "YouTube",
              type: "label"
          },
          {
            name: "Ideas",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 5 pomysłów na filmik na YouTube o tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Generator Tytułów",
            icon: "subtitles",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 10 pomysłów na tytuł filmu na YouTube o tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Opis Filmu",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz opis filmu na YouTube o tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Tagi",
            icon: "tag",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 20 tagów do filmiku na YouTube o tematyce [WSTAW TEMAT]."
            ]
          },
          {
              name: "Twitter",
              type: "label"
          },
          {
            name: "Post na Twittera",
            icon: "post_add",
            styles: ["neutralny", "humorystyczny", "zmuszający do myślenia", "5 akapitów"],
            fields: ["WSTAW TEMAT"],
            items: [
              { style: "neutralny", value: "Napisz post na Twittera w tematyce: [WSTAW TEMAT]." },
              { style: "humorystyczny", value: "Napisz humorystyczny post na Twittera w tematyce: [WSTAW TEMAT]." },
              { style: "zmuszający do myślenia", value: "Napisz posty na Twitter, który zaczniesz od pytania. Post będzie dotyczył tematyki [WSTAW TEMAT]." },
              { style: "5 akapitów", value: "Napisz post na twittera, który będzie zawierał 5 haseł (każdy od nowego wiersza). Post w tematyce [WSTAW TEMAT]." }
            ]
          },
          {
              name: "Pinterest",
              type: "label"
          },
          {
            name: "Pomysły",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT"],
            items: [
              "Zaproponuj 5 pomysłów na posty do Pinterest w tematyce: [WSTAW TEMAT]."
            ]
          },
          {
            name: "Post",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Wymyśl i napisz post na Pinterest w tematyce: [WSTAW TEMAT]."
            ]
          },
          {
            name: "Infografika",
            icon: "image",
            fields: ["WSTAW TEMAT"],
            items: [
              "Zaproponuj mi pomysł na Infografikę na Pineterst w tematyce: [WSTAW TEMAT]."
            ]
          },
          {
              name: "Podcast",
              type: "label"
          },
          {
            name: "Pomysły",
            icon: "tips_and_updates",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 10 pomysłów na odcinek podcastu w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Tytuł",
            icon: "subtitles",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz 3 tytuły podcastu w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Scenariusz",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz scenariusz odcinka podcastu w tematyce [WSTAW TEMAT]."
            ]
          },
          {
            name: "Opis Odcinka",
            icon: "post_add",
            fields: ["WSTAW TEMAT"],
            items: [
              "Napisz opis odcinka podcastu w tematyce [WSTAW TEMAT]."
            ]
          },
          {
              name: "Komentarze",
              type: "label"
          },
          {
            name: "Odpowiedź na negatywny",
            icon: "thumb_down",
            fields: ["WSTAW KOMENTARZ"],
            items: [
              "Odpisz kulturalnie i spokojnie na taki komentarz [WSTAW KOMENTARZ]."
            ]
          },
          {
            name: "Odpowiedź na pozytywny",
            icon: "thumb_up",
            fields: ["WSTAW KOMENTARZ"],
            items: [
              "Odpisz kulturalnie i spokojnie na taki komentarz [WSTAW KOMENTARZ]."
            ]
          },
          {
            name: "Z potencjałem sprzedaży",
            icon: "shopping_bag",
            fields: ["WSTAW KOMENTARZ"],
            items: [
              "Osoba napisała taki komentarz w poście na Facebooku: [WSTAW KOMENTARZ]. Napisz odpowiedź na komentarz, tak żeby przekonać osobę do zakupu i na końcu napisz żeby napisała do nas wiadomość prywatną."
            ]
          },
          {
            name: "Więcej obserwujących",
            icon: "group",
            fields: ["WSTAW KOMENTARZ"],
            items: [
              "Osoba napisała taki komentarz w poście na Facebooku: [WSTAW KOMENTARZ]. Napisz odpowiedź na komentarz i przekonaj do zaobserwowania naszego profilu na Instagramie."
            ]
          },
          {
            name: "Motywacja do odpowiedzi",
            icon: "replay",
            fields: ["WSTAW KOMENTARZ"],
            items: [
              "Odpisz na ten komentarz klientki: [WSTAW KOMENTARZ] w taki sposób, żeby zadać jej pytanie."
            ]
          },
          {
              name: "Inne",
              type: "label"
          },
          {
            name: "10X Generator Bonusów",
            icon: "psychology",
            fields: ["WSTAW NAZWĘ PRODUKTU"],
            items: [
              "Wymyśl mi 10 gratisów w postaci checklist, darmowych ebooków, tak żeby bonusy dały wartość produktowi: [WSTAW NAZWĘ PRODUKTU]."
            ]
          },
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
 
        
        if(this.$refs.style) {
          
          if(this.style) {
            
            this.selectedSchema.items.forEach(element => {

              
              if(element.style == this.style) {

                query = element.value;

              }

            });

          }

        } else {

          query = this.selectedSchema.items[0];

        }

        if(this.$refs.topic)
          query = query.replaceAll('[WSTAW TEMAT]', this.$refs.topic.value)

        if(this.$refs.occupation)
          query = query.replaceAll('[WSTAW OPIS ZAWODU]', this.$refs.occupation.value)

        if(this.$refs.industry)
         query = query.replaceAll('[WSTAW BRANŻĘ]', this.$refs.industry.value)

        if(this.$refs.productName)
          query = query.replaceAll('[WSTAW NAZWĘ PRODUKTU]', this.$refs.productName.value)

        if(this.$refs.comment)
          query = query.replaceAll('[WSTAW KOMENTARZ]', this.$refs.comment.value)
 
        if(this.$refs.name)
          query = query.replaceAll('[WSTAW IMIĘ]', this.$refs.name.value)
 
        if(this.$refs.tematyka)
          query = query.replaceAll('[WSTAW TEMATYKĘ PROFILU]', this.$refs.tematyka.value)
        
        if(this.$refs.client)
          query = query.replaceAll('[KTO JEST TWOIM KLIENTEM]', this.$refs.client.value)
  
        if(this.$refs.w_czym_pomagasz)
          query = query.replaceAll('[W CZYM POMAGASZ GRUPIE DOCELOWEJ]', this.$refs.w_czym_pomagasz.value)
  
        if(this.$refs.problem)
          query = query.replaceAll('[WSTAW PROBLEM KLIENTÓW]', this.$refs.problem.value)
  
 
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

        if(this.addCallToFollow) {

          query = query + "Dodaj wezwanie do obserwowania."

        }

        if(this.addHashtags) {

          query = query + "Dodaj hashtagi na końcu."

        }

 

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany tekst"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>