<template> 

  <div class="container pt-4">
 
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz polecenie dla wirtualnej asystentki:</label>

              <b-input-group>

                <b-form-textarea v-model="query" size="lg" rows="5" ref="query" placeholder="np. Wymyśl mi hasło reklamowe na promocję związaną z moją książką"></b-form-textarea>
                
              </b-input-group>

            </div>
  
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje tekst dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>


      <div class="mt-3">

        Przykładowe polecenia dla VA: <Br/>
        <code>Zaplanuj mi dzień. Mam do wykonania następujące zadania [TUTAJ WPISZ ZADANIA]</code><br/>
        <code>Streść w 5 punktach następujący artykuł [WKLEJ TREŚĆ ARTYKUŁU]</code><br/>
        <code>Wymyśl mi slogan reklamowy dla mojej firmy. [WPISZ CZYM ZAJMUJE SIĘ FIRMA]</code><br/>
        <code>Zaplanuj mi posty na Facebooku na 7 dni w branży [WPISZ BRANŻĘ]</code><br/>
        <code>Napisz email, w którym przekonujesz mojego kontrahenta, że w ciągu 7 dni pieniądze będą na jego koncie.</code><br/>
        <code>Wynegocjuj mi niższą cenę u dostawcy [WPISZ CO CHCESZ ZAMÓWIĆ]</code><br/>
        <code>Napisz ogłoszenie o pracę na stanowisko [WPISZ STANOWISKO] </code><br/>
        <code>Napisz mi agendę godzinnego spotkania z pracownikami na zoomie na temat [WPISZ TEMAT]</code><br/>
        <code>Wymyśl mi hasło reklamowe na promocję związaną z [WISZ OKAZJĘ DO PROMOCJI]</code><br/>
        <code>Zaplanuj mi weekend w [WPISZ MIEJSCOWOŚĆ]</code><br/>
        <code>Streść mi w 10 punktach książkę [WPISZ TYTUŁ KSIĄŻKI]</code><br/>
        <code>Napisz email do klienta, w którym chcesz się umówić na spotkanie w sprawie [OPISZ SPRAWĘ].</code><br/>
        <code>Ułóż treść rozmowy z pracownikiem na temat [WPISZ TEMAT ROZMOWY]</code><br/>
        <code>Wymyśl 3 argumenty do negocjacji z kontrahentem w celu obniżenia ceny [WSPISZ CO JEST PRZEDMIOTEM NEGOCJACJI]</code><br/>
        <code>Napisz tekst emaila, w którym zwalniasz pracownika działu [WPISZ DZIAŁ]</code><br/>
 
      </div>



    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppAiSellingMachine',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        query: "",
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {
  
      generateFinish: function() {

        if(!this.query) return false;

        
        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
 
         
        query = this.query;
 
        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany tekst"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>