<template> 

  <div class="container">
    1 Milion Coaching z Mirosławem Skwarkiem<br/><br/>

    <Loader v-if="isLoading"/>
      
    <div>

      <b-tabs content-class="mt-3">

        <b-tab title="7 dniowe wyzwanie" active>

          <div>
          
            <h5 class="mt-5">TikTok</h5>
            <hr/>
            <b-form-group
              label="Ile dziś dodałeś(aś) TikToków?"
              label-for="input-1"
              class="ml-3"
            >
              <b-form-input
                id="input-1"
                v-model="tiktok_count"
                type="number"
                placeholder="np. 3"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group>

            
            <b-form-group
              label="Ile wyświetleń miały w sumie TikToki przez 24h?"
              label-for="input-2"
              class="ml-3"
            >
              <b-form-input
                id="input-2"
                v-model="tiktok_views"
                type="number"
                placeholder="np. 1300"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group>            

            
            <b-form-group
              label="Ilu obserujących zdobyłeś(aś) na TikToku w ciągu 24h?"
              label-for="input-3"
              class="ml-3"
            >
              <b-form-input
                id="input-3"
                v-model="tiktok_followers"
                type="number"
                placeholder="np. 50"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group>    

            <h5 class="mt-5">Instagram</h5>
            <hr/>
            <b-form-group
              label="Ile dziś dodałeś(aś) rolek na Instagram?"
              label-for="input-4"
              class="ml-3"
            >
              <b-form-input
                id="input-4"
                v-model="instagram_count"
                type="number"
                placeholder="np. 3"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group>  


            <b-form-group
              label="Ile wyświetleń miały rolki na Instagramie?"
              label-for="input-5"
              class="ml-3"
            >
              <b-form-input
                id="input-5"
                v-model="instagram_views"
                type="number"
                placeholder="np. 1000"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group>  


            <b-form-group
              label="Ilu obserujących na Instagramie udało Ci się pozyskać w ciągu 24h?"
              label-for="input-6"
              class="ml-3"
            >
              <b-form-input
                id="input-6"
                v-model="instagram_followers"
                type="number"
                placeholder="np. 1000"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group> 


            <h5 class="mt-5">YouTube</h5>
            <hr/> 
            <b-form-group
              label="Ile dodałeś(aś) shortów na YouTube?"
              label-for="input-7"
              class="ml-3"
            >
              <b-form-input
                id="input-7"
                v-model="youtube_count"
                type="number"
                placeholder="np. 3"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group> 


            <b-form-group
              label="Ile wyświetleń wygenerowały shorty w ciągu 24h?"
              label-for="input-8"
              class="ml-3"
            >
              <b-form-input
                id="input-8"
                v-model="youtube_views"
                type="number"
                placeholder="np. 1000"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group> 
            

            <b-form-group
              label="Ilu subskrybentów udało Ci się pozyskać na YouTube?"
              label-for="input-9"
              class="ml-3"
            >
              <b-form-input
                id="input-9"
                v-model="youtube_followers"
                type="number"
                placeholder="np. 50"
                v-on:keyup="updateData()"
                v-on:change="updateData()"
              ></b-form-input>
            </b-form-group>              
 
            
            <h3 class="mt-5">Twoje wyniki</h3>
            1 Milion Coaching z Mirosławem Skwarkiem<br/><br/>

            <b-alert show variant="success">Udało Ci się dzisiaj dodać łącznie <b>{{ sm_count }}</b> wideo.</b-alert>
            <b-alert show variant="success">Udało Ci uzyskać dzisiaj łącznie <b>{{ sm_views }}</b> wyświetleń wideo.</b-alert>
            <b-alert show variant="success">Udało Ci sie dziś pozyskać <b>{{ sm_followers }}</b> potencjalnych klientów.</b-alert>


           
            <h3 class="mt-5">Ranking globalny</h3>
            1 Milion Coaching z Mirosławem Skwarkiem<br/><br/>

            Ranking na podstawie zsumowanych danych dotyczących wyświetleń.

            <b-table striped hover :fields="result_ranking_fields" :items="result_ranking" class="mt-5">
            
              <template #cell(userId)="data">
                <div :class="{ 'font-weight-bold': (data.value == userId) }"> 
                  #{{ data.value }} 
                  <span :class="{ 'd-none': (data.value !== userId) }">(Ty)</span>
                </div>
              </template>

            </b-table>


             
          </div>

        </b-tab>

        <b-tab title="Wygraj iPhone">

          <div class="mt-5">

            Osoba, która:<br/><br/>

            1. Kupi pakiet świąteczny (wykonane)<br/>
            2. Nagra video promujące 7 dniowe wyzwanie i umieści w social mediach<br/>
            3. Zbierze min. 1 certyfikat z 7 dniowego wyzwania<br/>
            4. Zaprosi najwięcej osób (do 22 grudnia 2022, 23:59) korzystając ze swojego unikalnego linku<br/>
            5. Wyzwanie trwa do czwartku, 22 grudnia 2022, 23:59.<br/><br/>

            otrzyma NOWEGO Apple iPhone 14 Pro 5G 128GB Black Dual SIM Nowy o wartości ponad 7000 zł,<br/>
            a dodatkowo będzie miała możliwość nagrania rolek w studio Mirka Skwarka pod jego czujnym okiem

            <br/><br/>
            <b-form-group
              label="Twój link do polecania"
              label-for="input-6"
            >
              <b-form-input
                id="input-6"
                :value="refLink"
                ref="refLink"
                type="text"
                readonly
                @click="selectRefLink"
              ></b-form-input>
            </b-form-group> 

          </div>

        </b-tab>

      </b-tabs>

    </div>

  </div>
</template>

<script>
  import Loader from '../../Loader';

  export default {
    name: 'AppInfluencerGenerator',
    components: {
      Loader
    },
    data() {
      return {
         isLoading: false,
         tiktok_count: null,
         tiktok_views: null,
         tiktok_followers: null,
         instagram_count: null,
         instagram_views: null,
         instagram_followers: null,
         youtube_count: null,
         youtube_views: null,
         youtube_followers: null,
         userId: null,
         result_ranking: '',
         result_ranking_fields: [        
          {
            key: 'userId',
            label: 'Użytkownik',
            sortable: false
          },
          {
            key: 'points',
            label: 'Ilość punktów',
            sortable: true
          }
        ],
         refLink: ''
      }
    },
    methods: {
      
      updateData: function() {

        this.axios.post(window.API_URL + "apps/MilionCoaching/updateData", {

          tiktok_count: parseInt(this.tiktok_count) ?? 0,
          tiktok_views: parseInt(this.tiktok_views) ?? 0,
          tiktok_followers: parseInt(this.tiktok_followers) ?? 0,
          instagram_count: parseInt(this.instagram_count) ?? 0,
          instagram_views: parseInt(this.instagram_views) ?? 0,
          instagram_followers: parseInt(this.instagram_followers) ?? 0,
          youtube_count: parseInt(this.youtube_count) ?? 0,
          youtube_views: parseInt(this.youtube_views) ?? 0,
          youtube_followers: parseInt(this.youtube_followers) ?? 0

        }, { withCredentials: true }).then((response) => {
        
        let data = response.data

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }
         
      })
        
      },

      selectRefLink: function() {

        this.$refs.refLink.select();

      }


    },
    computed: {

      sm_count() {
 
        return parseInt(this.tiktok_count ?? 0) + parseInt(this.instagram_count ?? 0) + parseInt(this.youtube_count ?? 0);

      },

      sm_views() {
  
        return parseInt(this.tiktok_views ?? 0) + parseInt(this.instagram_views ?? 0) + parseInt(this.youtube_views ?? 0);

      },

      sm_followers() {
 
        return parseInt(this.tiktok_followers ?? 0) + parseInt(this.instagram_followers ?? 0) + parseInt(this.youtube_followers ?? 0);

      }


    },
    created() {

     


    },
    mounted() {

      this.axios.get(window.API_URL + "apps/MilionCoaching/getData", { withCredentials: true }).then((response) => {
    
        let data = response.data

        console.log(data)

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }
        
        this.userId = data.userId
        this.refLink = "https://op.funnelstar.io/seria7-generator-influe-webinar-seria7-zapis/?r=" + data.userId

        if(data.milion_days_result_today) {

          this.tiktok_count = data.milion_days_result_today.tiktok_count;
          this.tiktok_views = data.milion_days_result_today.tiktok_views;
          this.tiktok_followers = data.milion_days_result_today.tiktok_followers;
          this.instagram_count = data.milion_days_result_today.instagram_count;
          this.instagram_views = data.milion_days_result_today.instagram_views;
          this.instagram_followers = data.milion_days_result_today.instagram_followers;
          this.youtube_count = data.milion_days_result_today.youtube_count;
          this.youtube_views = data.milion_days_result_today.youtube_views;
          this.youtube_followers = data.milion_days_result_today.youtube_followers;

        }

        this.result_ranking = data.milion_days_result_ranking;

      })

    },
    watch: {

      

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }

</style>