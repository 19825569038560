import MyProducts from './sections/MyProducts/index';
import Services from './sections/Services/index';
import Courses from './sections/Courses/index';
import Apps from './sections/Apps/index';
import App from './sections/App/index';
import Course from './sections/Course/index';
import AccessDenied from './sections/AccessDenied/index';

export default [

    { path: '/', component: MyProducts },
    { path: '/my-products', component: MyProducts },
    { path: '/services', component: Services },
    { path: '/dashboard', component: MyProducts },
    { path: '/courses', component: Courses }, 
    { path: '/apps', component: Apps }, 
    { path: '/app/:slug', component: App }, 
    { path: '/app/:slug/:schemaId', component: App }, 
    { path: '/course', component: Course }, 
    { path: '/course/:slug', component: Course }, 
    { path: '/course/:slug/:lesson', component: Course }, 
    { path: '/access-denied', component: AccessDenied }, 

]