<template> 

  <div>

    <Loader text="Ładowanie Twoich produktów" v-if="!user.id" />
  
    <div class="container pt-4" v-if="user.id">

      <h2 class="fw-600">Moje produkty</h2>
  
      <div class="pt-3">
  
        <b-alert variant="success" show>
          Jesteś w Akademii Social Media? Jeśli nie to <A href="https://pricing.funnelstar.io/asm/?code=webinar"><u>przetestuj teraz ASM. Kliknij tutaj ></u></A>
        </b-alert>  

        <b-card title="Kursy" class="mb-2 mt-4">
          <b-card-text>
            Sprawdź bibliotekę zakupionych i dostępnych kursów.
          </b-card-text>
          <b-button :to="'/courses'" variant="light">Idź do kursów <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">arrow_right_alt</span></b-button>
        </b-card>

        <b-card title="Aplikacje" class="mb-2 mt-3">
          <b-card-text>
            Przejdź do katalogu aplikacji.
          </b-card-text>
          <b-button :to="'/apps'" variant="light">Idź do aplikacji <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">arrow_right_alt</span></b-button>
        </b-card>

        <b-card title="Usługi" class="mb-2 mt-3">
          <b-card-text>
            Sprawdź jakie usługi obecnie oferujemy.
          </b-card-text>
          <b-button :to="'/services'" variant="light">Idź do usług <span class="material-symbols-sharp align-middle" style="font-size: 1rem;">arrow_right_alt</span></b-button>
        </b-card>

      </div>

    </div>

  </div>

</template>

<script>

  import Loader from '../../elements/Loader';

  export default {

    name: 'MyProducts',

    components: {
      Loader
    },

    data() {

      return {
        user: {
          id: null,
          permissions: []
        }
      }

    },

    created() {
      
      this.axios.get(window.API_URL + "user", { withCredentials: true }).then((response) => {

        let data = response.data 

        if(data.redirectTo) {

          top.location.href = data.redirectTo;

        }

        if(data.userId) {

          this.user.id = data.userId
          this.user.permissions = data.permissions

        }

      });

      // Set breadcumbs
      this.$root.$emit('system.breadcumber.set', [])

    },

  }
  
</script>

 