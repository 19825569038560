<template> 

  <div class="container pt-4">
     
    <b-input-group>
    
      <b-form-input v-model="keyword" placeholder="Wpisz frazę do analizy..." size="lg" autofocus v-on:keyup.enter="getSuggestions" :disabled="isSearching"></b-form-input>

      <b-input-group-append>

        <b-button variant="outline-white" v-if="keyword" @click="keyword = ''">

          <span class="material-symbols-outlined mt-1">close</span>

        </b-button>

        <b-button variant="outline-white" v-if="!keyword" @click="getSuggestions()">

          <span class="material-symbols-outlined" style="vertical-align:middle;">search</span> Szukaj 

        </b-button>

      </b-input-group-append>

    </b-input-group>
    
    <Loader v-if="isSearching"/>
 
    <div v-if="!keyword" class="pt-2">
    
      lub kliknij: 
      <span class="text-primary pointer ml-1" @click="loadFromExample('dieta')">dieta</span>, 
      <span class="text-primary pointer ml-1" @click="loadFromExample('mlm')">mlm</span>, 
      <span class="text-primary pointer ml-1" @click="loadFromExample('zarabianie w internecie')">zarabianie w internecie</span>, 
      <span class="text-primary pointer ml-1" @click="loadFromExample('iphone')">iphone</span>, 
      <span class="text-primary pointer ml-1" @click="loadFromExample('makijaż')">makijaż</span>
       
    </div>

    <div class="mt-2" v-if="(results.length > 0) && keyword">

      <b-form-group>
        <b-form-radio-group v-model="mode" :options="modeOptions" buttons button-variant="light">
        </b-form-radio-group>
      </b-form-group>

      <b-row align-v="center" style="min-height: 40px;">

        <b-col lg="8">

          <span v-if="mode == 'keywords'">Ilość słów kluczowych: </span>
          <span v-if="mode == 'questions'">Ilość pytań: </span>
          
          <B>{{ results.length | splitNumber }}</B> |

          <span v-if="(selected.length > 0)" class="ml-1">

            <span class="text-primary pointer" @click="copy">
              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka ({{ selected.length | splitNumber }})
            </span>

            <span class="text-primary pointer ml-1" @click="selected = []">
              Odznacz wszystko ({{ selected.length | splitNumber }})
            </span>

          </span>

          <span v-if="(selected.length == 0)" class="ml-1">

            <span class="text-primary pointer" @click="selectAll">
              <span class="material-symbols-sharp align-middle">done</span>
              Zaznacz wszystko
            </span>

            <span class="text-primary pointer" @click="randomKeyword">
              <span class="material-symbols-sharp align-middle">shuffle</span>
              Losuj słowo
            </span>

          </span>

        </b-col>

        <b-col lg="4">

          <div v-if="(selected.length > 0)">

            <b-button variant="outline-success mr-1" @click="generateFinish()">
              <span class="material-symbols-outlined align-middle">
                tips_and_updates
              </span>
              Pomysły na Rolki
            </b-button>
            
            <b-button variant="outline-success" @click="fastReelGenerate()">
              <span class="material-symbols-outlined align-middle">
                bolt
              </span>
              Szybka Rolka!
            </b-button>

          </div>

        </b-col>

      </b-row>
 
      <b-alert show v-if="fastReel" variant="success" class="mt-2">
        {{ fastReel }}
      </b-alert>
      
      <b-card header-tag="header" class="mt-4" v-if="isFinish">

        <template #header>

          <h6 class="mb-0 mt-2 float-left">Wygenerowane pomysły na Rolki</h6>

          <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

            <span class="material-symbols-outlined mt-1">close</span>

          </b-button>

        </template>

        <blockquote class="blockquote mb-0">
          
          <textarea class="form-control" ref="finishContent" rows="8" v-model="finishContent" placeholder="" v-on:focus="$event.target.select()"></textarea>

          <small>
            
          <span class="material-symbols-sharp align-middle small">tips_and_updates</span>
           Ilość pomysłów: <B class="text-success">{{ finishLinesCount | splitNumber }}</B></small>

          <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

            <span class="material-symbols-sharp align-middle">content_copy</span>
            Kopiuj do schowka

          </div>

        </blockquote>

      </b-card>
 
      <b-table id="table" class="mt-4" striped hover :fields="tableFields" :items="results" :per-page="perPage" :current-page="currentPage">
      
        <template #cell(keyword)="data">
          <b-form-checkbox ref="selectedKeywords" v-model="selected" name="selectedKeywords" :value="data.value">{{ data.value }}</b-form-checkbox>
        </template>

        <template #cell(volume)="data">
          {{ data.value | splitNumber }}
        </template>

      </b-table>

      <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table"
    ></b-pagination>

      <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   

    </div>

  </div>
</template>

<script>
  import Loader from '../../Loader';

  export default {
    name: 'AppReelsKeywordsGenerator',
    components: {
      Loader
    },
    data() {
      return {
        perPage: 100,
        currentPage: 1,
        keyword: '',
        isSearching: false,
        isFinish: false,
        tableFields: [
          {
            key: 'keyword',
            label: 'Słowo kluczowe',
            sortable: false
          },
          {
            key: 'volume',
            label: 'Wyszukiwań miesięcznie',
            sortable: true
          },
        ],
        results: [],
        resultsOriginal: [],
        mode: 'keywords',
        modeOptions: [
          { 
            text: 'Słowa kluczowe', 
            value: 'keywords', 
            html: '<span class="material-symbols-outlined align-middle">list</span> Słowa kluczowe' 
          },
          { 
            text: 'Pytania', 
            value: 'questions', 
            html: '<span class="material-symbols-outlined align-middle">quiz</span> Pytania' 
          }
        ],
        selected: [],
        selectedText: '',
        finishContent: '',
        fastReel: '',
        finishLinesCount: 0,
        schemaKeywords: [
          "[SŁOWO KLUCZOWE] co robić a czego nie robić",
          "6 TIPÓW na [SŁOWO KLUCZOWE] w 60 sekund",
          "5 tricków związanych z [SŁOWO KLUCZOWE] którymi zaskoczysz znajomych",
          "5 mitów nt. [SŁOWO KLUCZOWE] których nie warto powtarzać.",
          "Sekretny trick [SŁOWO KLUCZOWE] #001",
          "Przestań robić TO w [SŁOWO KLUCZOWE] jeżeli chcesz osiągnąć cel",
          "5 sposobów na podniesienie [SŁOWO KLUCZOWE] na wyższy poziom",
          "Nowa funkcja w [SŁOWO KLUCZOWE]  o której nie miałeś pojęcia",
          "Nowa, ukryta funkcja w [SŁOWO KLUCZOWE] o której 99% nie miało pojęcia.",
          "5 nowych funkcji w [SŁOWO KLUCZOWE]  o których nie miałeś pojęcia",
          "3 nowe funkcje w [SŁOWO KLUCZOWE]  o których większość nie miało pojęcia.",
          "Nie rób tych 3 błędów na [SŁOWO KLUCZOWE] jeżeli nie chcesz ośmieszyć się przed znajomymi",
          "3 genialne funkcje w [SŁOWO KLUCZOWE] o których nie miałeś pojęcia.",
          "Jeżeli chcesz osiągnąć cel stosując [SŁOWO KLUCZOWE] nie popełniaj tego błędu",
          "Chcesz osiągnąć cel korzystając z [SŁOWO KLUCZOWE]? Jeżeli tak to nie popełniaj tego błędu",
          "3 głupie błędy, które zabijają twoją satysfakcję z [SŁOWO KLUCZOWE]",
          "Rób to regularnie a osiągniesz mistrzostwo w [SŁOWO KLUCZOWE]",
          "Ukryty sekret. Wystarczy robić te 3 rzeczy w [SŁOWO KLUCZOWE] żeby osiągnąć cel.",
          "A co jeżeli powiem Ci, że od wielu lat robisz to źle z [SŁOWO KLUCZOWE]",
          "Tajna sztuczka w [SŁOWO KLUCZOWE], która powinna być zakazana.",
          "Strona internetowa związana z [SŁOWO KLUCZOWE] o której nie miałeś pojęcia",
          "Tajna strona o tematyce [SŁOWO KLUCZOWE] o której powinien wiedzieć każdy.",
          "Wytrawni użytkownicy [SŁOWO KLUCZOWE] wykładają się na tym. Zobacz.",
          "Tej JEDNEJ rzeczy nie wiesz prawdopodobnie o [SŁOWO KLUCZOWE]",
          "5 TIPÓW na [SŁOWO KLUCZOWE]",
          "TOP 3 pomyłki w [SŁOWO KLUCZOWE], które czynią Cię amatorem",
          "TOP 3 błędy w używaniu [SŁOWO KLUCZOWE]",
          "Unikaj tych 3 BŁĘDÓW w [SŁOWO KLUCZOWE] jeżeli nie chcesz narazić się naśmieszność.",
          "Te TOP 3] błędy w [SŁOWO KLUCZOWE] powoduje, że śmieją się z ciebie za plecami",
          "TOP 3 krytyczne błędy w nauce [SŁOWO KLUCZOWE].",
          "TOP 5 krytycznych błędów w używaniu [SŁOWO KLUCZOWE].",
          "Tutorial jak osiągnąć mistrzostwo w [SŁOWO KLUCZOWE] w  mniej niż 24 H",
          "[SŁOWO KLUCZOWE] krok po kroku. Tutorial",
          "[SŁOWO KLUCZOWE] w 30 minut",
          "Prosty sposób na [SŁOWO KLUCZOWE]",
          "Banalnie prosty sposób na [SŁOWO KLUCZOWE] w mniej niż 30 minut",
          "Nowy trend na [SŁOWO KLUCZOWE].",
          "Ile  [SŁOWO KLUCZOWE] dodawać dziennie?",
          "Przestań robić to w [SŁOWO KLUCZOWE] w taki sposób.",
          "Najgłupszy sposób na [SŁOWO KLUCZOWE]",
          "To jest proste wyjaśnienie dlaczego nie masz efektów w [SŁOWO KLUCZOWE]",
          "To najprostsze wyjaśnienie, że nie masz efektów w [SŁOWO KLUCZOWE]",
          "Rób ta JEDNĄ rzecz inaczej w [SŁOWO KLUCZOWE] a szybko zamierzony cel.",
          "3 [SŁOWO KLUCZOWE] hacks, które musisz znać",
          "Numer 1 powód, przez który nie osiągasz wyników w [SŁOWO KLUCZOWE]",
          "Prosty [SŁOWO KLUCZOWE] trick, który musisz znać",
          "Prosty [SŁOWO KLUCZOWE] trick, który musisz natychmiast wypróbować",
          "Łatwa do wdrożenia strategia na rozwój [SŁOWO KLUCZOWE] Działa natychmiast.",
          "Łatwa strategia na [SŁOWO KLUCZOWE]. Efekty już po 24 h",
          "To prawdziwa magia  [SŁOWO KLUCZOWE]. Łatwa strategia na osiągnięciu celu w mniej niż 30 minut.",
          "STOP! Zatrzymaj się. Poznaj łatwą strategię na [SŁOWO KLUCZOWE].",
          "Szok! Banalne rozwiązanie problemu [SŁOWO KLUCZOWE], które rozwiążesz w 24 godziny albo krócej.",
          "Perfekcyjna długość [SŁOWO KLUCZOWE] aby osiągnąć zamierzony cel.",
          "Nowa funkcja [SŁOWO KLUCZOWE], która rozwala mózg.",
          "Nowa funkcja [SŁOWO KLUCZOWE], która wyrwie Cię z butów.",
          "Prosty [SŁOWO KLUCZOWE] tutorial",
          "Prosty przewodnik po [SŁOWO KLUCZOWE] dla zapracowanych",
          "Prosty przewodnik po [SŁOWO KLUCZOWE] dla mocno zajętych ludzi",
          "Prosty przewodnik po [SŁOWO KLUCZOWE]",
          "TOP 5 sposobów na większe zaangażowanie w [SŁOWO KLUCZOWE]",
          "Pierwszy raz na [SŁOWO KLUCZOWE]? Poznaj te 3 ukryte funkcje",
          "Numer 1 wśród pomyłek w obszarze [SŁOWO KLUCZOWE]",
          "TOP kosztowna pomyłka wśród początkujących, zaczynających przygodę z [SŁOWO KLUCZOWE]",
          "Nie robię tego w [SŁOWO KLUCZOWE] bo wiem czym to grozi. Zobacz wideo.",
          "[SŁOWO KLUCZOWE] ALERT!",
          "Plotki nt. [SŁOWO KLUCZOWE], które okazały się prawdą",
          "3 plotki nt. [SŁOWO KLUCZOWE], które okazały się prawdą",
          "3 plotki nt. [SŁOWO KLUCZOWE], które okazały się kompletną bzdurą",
          "Nowa aktualizacja [SŁOWO KLUCZOWE] o której musisz wiedzieć",
          "Aktualizacja [SŁOWO KLUCZOWE] o które wie tylko garstka zapaleńców.",
          "3  pomysły na [SŁOWO KLUCZOWE]",
          "UWAGA! Aktualizacja algorytmów [SŁOWO KLUCZOWE]. Co musisz?",
          "3 rzeczy których nie wiedziałeś o [SŁOWO KLUCZOWE]",
          "3 super fakty których nie wiedziałeś o [SŁOWO KLUCZOWE]",
          "3  mało  znane fakty o [SŁOWO KLUCZOWE]",
          "Ludzie pytają mnie jak osiągnąłem cel w [SŁOWO KLUCZOWE]  Oto odpowiedź…",
          "Jak mieć więcej [SŁOWO KLUCZOWE] w krótkim czasie?",
          "Najlepsza droga do [SŁOWO KLUCZOWE]",
          "Pomysły na [SŁOWO KLUCZOWE] o których prawdopodobnie nie masz pojęcia",
          "Rób tą JEDNĄ rzecz codziennie w [SŁOWO KLUCZOWE] a efekty Cię zaskoczą.",
          "Jesteś zdołowany wynikami z [SŁOWO KLUCZOWE]? Spróbuj tego!",
          "3 zasady pracy [SŁOWO KLUCZOWE] o których nie możesz zapominać.",
          "3 głupie nawyki, które rujnują twoje wyniki w [SŁOWO KLUCZOWE]",
          "Rób to inaczej w [SŁOWO KLUCZOWE] a natychmiast zobaczysz wyniki.",
          "Mam dla Ciebie 2 ciekawe metody, które pomogą Ci z [SŁOWO KLUCZOWE]",
          "Jeżeli słyszałeś o [SŁOWO KLUCZOWE] to nie przewijaj tego filmiku bo mam dla Ciebie 3 fajne podpowiedzi. Po pierwsze…",
          "Dzisiaj krótko o [SŁOWO KLUCZOWE] dlatego nie pomijaj tego wideo.",
          "Wiele osób pyta mnie o [SŁOWO KLUCZOWE] już Wam odpowiadam",
          "Jak zaczęła się moja historia z [SŁOWO KLUCZOWE]? Już Ci odpowiadam.",
          "Ten film Ci się przyda. Zapisz go na później bo mówię w nim o 3 ważnych rzeczach związanych z [SŁOWO KLUCZOWE]"
        ],
        schemaQuestions: [
          "Często dostaję pytanie [SŁOWO KLUCZOWE]? Już Ci odpowiadam",
          "Często pytacie w emailach [SŁOWO KLUCZOWE]? Oto odpowiedź.",
          "Ktoś w komentarzu pod poprzednią rolka zapytał [SŁOWO KLUCZOWE]? Odpowiedź jest prosta.",
          "[SŁOWO KLUCZOWE]? Oto odpowiedź",
          "[SŁOWO KLUCZOWE]? Nie przewijaj tego wideo jeżeli chcesz poznać odpowiedź.",
          "Ważne pytanie, które należy sobie zadać to [SŁOWO KLUCZOWE]? Oto odpowiedź",
          "Czy zastanawia się czasami [SŁOWO KLUCZOWE]? Oto odpowiedź",
          "Często pada pytanie [SŁOWO KLUCZOWE]? Oto odpowiedź",
          "Nie ma tygodnia żeby ktoś nie pytał mnie o [SŁOWO KLUCZOWE]? Oto odpowiedź",
          "Daj mi 60 sekund a powiem Ci [SŁOWO KLUCZOWE]? Zaczynamy",
          "Czy ty też zadajesz sobie pytanie [SŁOWO KLUCZOWE]? Oto odpowiedź",
          "Nie znasz odpowiedzi na pytanie [SŁOWO KLUCZOWE]? Świetnie bo mam dla Ciebie odpowiedź. Po pierwsze …",
          "Pytacie mnie [SŁOWO KLUCZOWE]? Nie przewijaj tego wideo jeżeli chcesz poznać odpowiedź.",
          "Jako ekspert często dostaje pytanie [SŁOWO KLUCZOWE]? Oto na co warto zwrócić uwagę. Po pierwsze…",
          "Posłuchaj, czy nie zadajesz sobie często pytania: [SŁOWO KLUCZOWE]? Już Ci odpowiadam. Zanim usłyszysz odpowiedź zaobserwuj mnie. Startujemy.",
          "[SŁOWO KLUCZOWE]? Już Ci odpowiadam. Startujemy",
          "[SŁOWO KLUCZOWE]? to pytanie powraca jak bumerang. Już śpieszę z odpowiedzią. Zanim jednek odpowiem zaobserwuj mnie żeby nie przegapić tego typu porad. Zaczynamy, po pierwsze…",
          "[SŁOWO KLUCZOWE]? WOW! Ale super pytanie. Już Ci odpowiadam. Po pierwsze…",
          "[SŁOWO KLUCZOWE]? Zanim odpowiem zaobserwuj mnie po więcej takich porad, a teraz startujemy. Posłuchaj",
          "Nie pomijaj tego wideo jeżeli chcesz poznać odpowiedź na pytanie [SŁOWO KLUCZOWE]?",
          "[SŁOWO KLUCZOWE]? Może wydawać się to proste, ale na odpowiedź potrzebuję 60 sekund, dlatego nie pomija tego wideo.",
          "[SŁOWO KLUCZOWE]? Oglądaj to wideo do końca jeżeli chcesz poznać odpowiedź.",
          "[SŁOWO KLUCZOWE]? No i to jest pytanie, już Ci odpowiadam…"
        ]
      }
    },
    methods: {

      randomKeyword: function() { 

        this.selected = []

        var selected = []

        var item = this.results[Math.floor(Math.random() * this.results.length)];

        selected.push(item.keyword);

        this.selected = selected

      },

      fastReelGenerate: function() {
 
        this.fastReel = ""

        var mix = []

        if(this.mode == 'keywords') {

          mix = this.schemaKeywords

        }

        if(this.mode == 'questions') {

          mix = this.schemaQuestions
          
        }

        var reel = mix[Math.floor(Math.random() * mix.length)];

        reel = reel.replaceAll('[SŁOWO KLUCZOWE]', this.selected[0])

        // reel = reel.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase())

        this.fastReel = reel

      },

      generateFinish: function() {

        this.isFinish = true

        this.finishContent = ""
        this.fastReel = ""

        var mix = []

        if(this.mode == 'keywords') {

          mix = this.schemaKeywords

        }

        if(this.mode == 'questions') {

          mix = this.schemaQuestions
          
        }

        this.selected.forEach((item) => {

          mix.forEach((mixItem) => {
          
            var doneText = mixItem;

            doneText = doneText.replaceAll('[SŁOWO KLUCZOWE]', item)

            // doneText = doneText.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase())

            this.finishContent = this.finishContent + doneText + "\n"

          })


        })

      },

      setKeywordsMode: function () {

        this.isSearching = true   

        this.selected = []

        this.results = this.resultsOriginal

        this.isSearching = false

        this.isFinish = false

        this.fastReel = ""

        this.finishContent = ""

      },

      setQuestionsMode: function () {

        this.isSearching = true   
        this.isFinish = false   
        this.fastReel = ""
        
        this.selected = []
        
        var filtered = []
        var questions = ["kiedy", "jak", "co ", " co ", "dlaczego ", "na czym"]
        var results = this.results

        questions.forEach(function (item) {

          var filtered_tmp = results.filter(function(el) { 
            return el.keyword.indexOf(item) > -1 
          }); 
 
          filtered = filtered.concat(filtered_tmp);

        });

        filtered = filtered.filter((v,i,a)=>a.findIndex(v2=>(v2.keyword===v.keyword))===i)

        this.results = filtered

        this.isSearching = false

        this.isFinish = false
        this.fastReel = ""

        this.finishContent = ""

      },

      getSuggestions: function () {

        this.isSearching = true
        this.isFinish = false
        this.fastReel = ""

        
        this.results = []
        this.selected = []
        this.mode = 'keywords'

        this.axios.get(window.API_URL + "apps/reelsKeywordsGenerator/getKeywordData/?keyword=" + this.keyword, { withCredentials: true }).then((response) => {

          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          this.results = data
          this.resultsOriginal = data

          this.isSearching = false

        })

      },

      loadFromExample: function(keyword) {

        this.keyword = keyword
        this.getSuggestions()

      },

      copyResults: function() {
 
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$bvToast.toast('Pomysły na Rolki zostały skopiowane do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      },

      copy: function() {

        this.$refs.selectedText.style.display = "block";

        this.$refs.selectedText.focus();

        document.execCommand('copy');

        window.scrollTo(0, 0)

        this.$refs.selectedText.style.display = "none"

        this.$bvToast.toast('Słowa kluczowe zostały skopiowane do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

       
      },

      selectAll: function() {

        this.selected = []

        var selected = []

        this.results.forEach(function (item) {

          selected.push(item.keyword);

        });

        this.selected = selected
      }

    },
    computed: {
      rows() {
        return this.results.length
      }
    },
    created() {

      this.$watch('selected', (value) => {

        this.selectedText = ""

        value.forEach((item) => {

          this.selectedText = this.selectedText + item + "\n"

        });

      })


      this.$watch('mode', (value) => {

        if(value == 'questions') {

          this.setQuestionsMode()

        }

        if(value == 'keywords') {

          this.setKeywordsMode()

        }
 
      })

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })

      


      


    }

  }
</script>

<style>

  .pointer { cursor: pointer; }

</style>