<template> 

  <div class="container pt-4">
     
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">
 
          <div class="mt-4">

            <div class="mt-3">

              <label>Wpisz pytanie, cel lub problem biznesowy który chcesz rozwiązać:</label>

              <b-input-group>

                <b-form-input v-model="question" size="lg" ref="question" placeholder="np. czy produkt xyz ma szansę na sukces na rynku, w co byś zainwestował 100 dolarów"></b-form-input>
                
              </b-input-group>
 
            </div>

             
            <div class="mt-3">

              <label>Wybierz mentora:</label>

              <b-input-group>

                <b-form-select v-model="mentor" ref="mentor" size="lg" :options="mentors"></b-form-select>
                
              </b-input-group>

            </div>
 
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Poproś o poradę
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje poradę <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Porada</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
   
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>
 
    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppBussinesMentorAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        query: "",
        question: "",
        mentors: [
          "Jeff Bezos - Założyciel Amazon",
          "Elon Musk - CEO Tesla, SpaceX",
          "Bill Gates - Założyciel Microsoft",
          "Mark Zuckerberg - Założyciel Facebook",
          "Warren Buffet - Berkshire Hathaway",
          "Larry Page - Współzałożyciel Google",
          "Sergey Brin - Współzałożyciel Google",
          "Steve Jobs - Współzałożyciel Apple",
          "Jack Ma - Założyciel Alibaba",
          "Mukesh Ambani - Przewodniczący i największy udziałowiec Reliance Industries",
          "Bernard Arnault - CEO LVMH",
          "Carlos Slim Helu - Założyciel Grupo Carso",
          "Amancio Ortega - Założyciel Inditex, znanej z marki Zara",
          "Larry Ellison - Współzałożyciel Oracle Corporation",
          "Michael Bloomberg - Założyciel Bloomberg LP",
          "Richard Branson - Założyciel Virgin Group",
          "Ma Huateng - Założyciel Tencent",
          "Li Ka-Shing - Założyciel Cheung Kong Industries",
          "Ray Dalio - Założyciel Bridgewater Associates",
          "Masayoshi Son - Założyciel SoftBank",
          "Ingvar Kamprad - Założyciel IKEA",
          "Wang Jianlin - Założyciel Dalian Wanda Group",
          "Phil Knight - Założyciel Nike",
          "Nassef Sawiris - CEO Orascom Construction Industries",
          "Laxmi Mittal - CEO ArcelorMittal",
          "Charles Koch - CEO Koch Industries",
          "David Koch - Współwłaściciel Koch Industries",
          "Francois Pinault - Założyciel Kering",
          "Stefan Persson - Właściciel H&M",
          "Gabe Newell - Współzałożyciel Valve Corporation",
          "Azim Premji - Przewodniczący Wipro Limited",
          "George Soros - Założyciel Soros Fund Management",
          "Alice Walton - Córka założyciela Walmart, Sam Walton",
          "Lee Shau Kee - Założyciel Henderson Land Development",
          "Robin Li - Współzałożyciel Baidu",
          "Patrick Drahi - Założyciel Altice",
          "Shiv Nadar - Założyciel HCL Technologies",
          "Robert Kuok - Założyciel Kuok Group",
          "Aliko Dangote - Założyciel Dangote Group",
          "Giovanni Ferrero - CEO Ferrero Group",
          "Zhang Zhidong - Współzałożyciel Tencent",
          "Pallonji Mistry - Właściciel Shapoorji Pallonji Group",
          "Elon Musk - Założyciel SpaceX, Tesla, Inc.",
          "Rupert Murdoch - Założyciel News Corp",
          "Pony Ma - Współzałożyciel Tencent",
          "Michael Dell - Założyciel Dell Technologies",
          "Theo Albrecht - Współzałożyciel Aldi",
          "Karl Albrecht - Współzałożyciel Aldi",
          "Dieter Schwarz - Właściciel Schwarz-Gruppe (Lidl)",
          "Sheldon Adelson - Założyciel Las Vegas Sands"
        ],
        mentor: "",
        finishContent: '',
        selectedSchema: null
      }
    },
    methods: {
  
      generateFinish: function() {

        if(!this.mentor) return false;
        if(!this.question) return false;

        
        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
         
        query = "Wciel się w znanego przedsiębiorcę " + this.mentor + " i udziel porady jak rozwiązałbyś problem " + this.question + " gdybyś był " + this.mentor + ".";
 
        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data
 
          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany artykuł"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },
 
      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Treść została skopiowana do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
  code { color: #4e82f7 !important; }
</style>