<template> 

  <div class="container pt-4">
 
    <div>
      
      <b-row align-v="center">

        <b-col lg="12">

           
          <div class="mt-4">
              
            <div>

              <label>Wpisz tematykę:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="topic" placeholder="np. odchudzanie"></b-form-input>
                
              </b-input-group>

            </div>

              
            <div class="mt-3">

              <label>Wybierz celebrytę:</label>

              <b-input-group>

                <b-form-select v-model="celebrite" ref="celebrite" :options="this.celebrites"></b-form-select>
                
              </b-input-group>

            </div>
            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
            <b-form-checkbox v-model="addCallToFollow" value="true">Dodaj wezwanie do obserwowania</b-form-checkbox>
            <b-form-checkbox v-model="addHashtags" value="true">Dodaj hashtagi</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje tekst dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowany tekst</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppCelebrityPostWriterAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        celebrite: "",
        celebrites: [
          "Robert Kiyosaki",
          "Anthony Robbins",
          "Les Brown",
          "Gary Vaynerchuk",
          "Oprah Winfrey",
          "Jim Rohn",
          "Zig Ziglar",
          "Brian Tracy",
          "Grant Cardone",
          "Selena Gomez",
          "Dwayne 'The Rock' Johnson",
          "Kylie Jenner",
          "Kim Kardashian",
          "Cristiano Ronaldo",
          "Lionel Messi",
          "Neymar Jr.",
          "Ariana Grande",
          "Beyoncé",
          "Taylor Swift",
          "Justin Bieber",
          "Katy Perry",
          "Rihanna",
          "Shakira",
          "Nicki Minaj",
          "Cardi B",
          "Ed Sheeran",
          "Bruno Mars",
          "Drake",
          "Shawn Mendes",
          "Bad Bunny",
          "Justin Timberlake",
          "Ellen DeGeneres",
          "Pink",
          "LeBron James",
          "Kevin Hart",
          "Floyd Mayweather",
          "Stephen Curry",
          "The Weeknd",
          "Chris Brown",
          "J Lo",
          "50 Cent",
          "Snoop Dogg",
          "Will Smith",
          "Ice Cube",
          "Ludacris",
          "Dr. Dre",
          "Eminem",
          "Kendrick Lamar",
          "Jay-Z",
          "Snoop Dogg",
          "Adam Levine",
          "Charlie Puth",
          "Post Malone",
          "Mariah Carey",
          "Halle Berry",
          "Angelina Jolie",
          "Sandra Bullock",
          "Miley Cyrus",
          "Demi Lovato",
          "Zendaya",
          "Lady Gaga",
          "Harry Styles",
          "Niall Horan",
          "Liam Payne",
          "Louis Tomlinson",
          "Zayn Malik",
          "Usher",
          "Jennifer Aniston",
          "Gwyneth Paltrow",
          "Cameron Diaz",
          "Jessica Alba",
          "Mila Kunis",
          "Emma Watson",
          "Emma Stone",
          "Ryan Reynolds",
          "Robert Downey Jr.",
          "Chris Evans",
          "Mark Ruffalo",
          "Chris Hemsworth",
          "Scarlett Johansson",
          "Dax Shepard",
          "Ashton Kutcher",
          "Jennifer Lopez",
          "Alex Rodriguez",
          "David Beckham",
          "Cristiano Ronaldo Jr.",
          "Gisele Bundchen",
          "Channing Tatum",
          "Jenna Dewan",
          "Sofia Vergara",
          "Ellen Pompeo",
          "Katherine Heigl",
          "Kerry Washington",
          "Mindy Kaling",
          "Priyanka Chopra",
          "Deepika Padukone",
          "Aishwarya Rai",
          "Alia Bhatt",
          "Sonam Kapoor",
          "Varun Dhawan",
          "Ranveer Singh",
          "Tiger Shroff",
          "Hrithik Roshan",
          "Shraddha Kapoor",
          "Saif Ali Khan",
          "Shah Rukh Khan",
          "Aamir Khan",
          "Salman Khan",
          "Akshay Kumar",
          "Julia Wieniawa",
          "Ania Lewandowska",
          "Paulina Sykut",
          "Dominika Gwit",
          "Magdalena Różczka",
          "Kuba Wojewódzki",
          "Maja Sablewska",
          "Ewa Chodakowska",
          "Sylwester Wardęga",
          "Radosław Majdan",
          "Agnieszka Maciąg",
          "Marta Linkiewicz",
          "Patrycja Markowska",
          "Dorota Wellman",
          "Janusz Korwin-Mikke",
          "Patryk Vega",
          "Zuzanna Bijoch",
          "Piotr Kędzierski",
          "Robert Lewandowski",
          "Kamil Stoch",
          "Agata Młynarska",
          "Szymon Majewski",
          "Natalia Siwiec",
          "Mariusz Pudzianowski",
          "Mateusz Damięcki",
          "Anna Dereszowska",
          "Kamil Bednarek",
          "Kuba Grabowski",
          "Bartosz Żukowski",
          "Weronika Rosati",
          "Szymon Hołownia",
          "Ania Andrzejewska",
          "Paulina Młynarska",
          "Paweł Wilczak",
          "Monika Pyrek",
          "Artur Boruc",
          "Małgorzata Kożuchowska",
          "Arkadiusz Milik",
          "Agnieszka Woźniak-Starak",
          "Bartek Kasprzykowski",
          "Agnieszka Szulim",
          "Karolina Szostak",
          "Katarzyna Tusk",
          "Małgosia Socha",
          "Bartosz Kapustka",
          "Kamil Grosicki",
          "Martyna Wojciechowska",
          "Jakub Błaszczykowski",
          "Kinga Rusin",
          "Andrzej Gołota",
          "Ewa Drzyzga",
          "Łukasz Fabiański",
          "Wojciech Szczęsny",
          "Tomasz Schimscheiner",
          "Adrian Chmielarz",
          "Kamil Durczok",
          "Janek Samołyk",
          "Łukasz Jakóbiak",
          "Maria Niklińska",
          "Dominika Tajner",
          "Radosław Majdan",
          "Marcin Prokop",
          "Agnieszka Hyży",
          "Bartłomiej Topa",
          "Sebastian Stankiewicz",
          "Ania Dąbrowska",
          "Edyta Herbuś",
          "Monika Mrozowska",
          "Dorota Szelągowska",
          "Bartosz Kurek",
          "Joanna Jędrzejczyk",
          "Mariusz Czerkawski",
          "Maciej Kurzajewski",
          "Anna Popek",
          "Maciej Dowbor",
          "Agnieszka Radwańska",
          "Maciej Orłoś",
          "Cezary Pazura",
          "Robert Makłowicz",
          "Piotr Żyła",
          "Robert Lewandowski",
          "Marta Wierzbicka",
          "Bartosz Bielenia",
          "Paulina Sykut-Jeżyna",
          "Weronika Marczuk",
          "Agnieszka Chylińska",
          "Daniel Magdziak",
          "Maciej Musiał",
          "Joanna Jędrzejczyk",
          "Adam Grabowski",
          "Daniel Oziewicz",
          "Magdalena Lamparska",
          "Grzegorz Krychowiak",
          "Bartłomiej Topa",
          "Kinga Rusin",
          "Adam Małysz",
          "Mateusz Borek",
          "Marcin Gortat",
          "Joanna Moro"
        ],
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
         
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "Napisz post (10 zdań) na Instagram w stylu [WSTAW CELEBRYTĘ] na temat: [WSTAW TEMAT].";
 
        if(this.$refs.celebrite)  
          query = query.replaceAll('[WSTAW CELEBRYTĘ]', this.$refs.celebrite.value)

        if(this.$refs.topic)
          query = query.replaceAll('[WSTAW TEMAT]', this.$refs.topic.value)
 
 
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

        if(this.addCallToFollow) {

          query = query + "Dodaj wezwanie do obserwowania."

        }

        if(this.addHashtags) {

          query = query + "Dodaj hashtagi na końcu."

        }

 
        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowany tekst"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>