<template> 

  <div class="container pt-4">

    <div v-if="!selectedSchema">

      Wybierz styl:
 
      <b-list-group class="mt-3">
        <b-list-group-item :class="{ 'text-primary': (item.type == 'label'), 'pl-3': (item.type !== 'label') }"  v-for="item in schema" class="pointer" :key="item.name" button @click="selectSchema(item)">
          
          <span class="material-symbols-outlined align-middle" v-if="item.icon">{{ item.icon }}</span> {{ item.name }}

        </b-list-group-item>
      </b-list-group>
 
    </div>
 
    <div v-if="selectedSchema">
      
      <b-row align-v="center">

        <b-col lg="12">

          <h2>
            
            <button class="btn btn-white" size="sm" @click="selectedSchema = ''">
              <span class="material-symbols-outlined align-middle">
                keyboard_return
              </span>
            </button> 
            
            {{ this.selectedSchema.name }}
          
          </h2>


          <div class="mt-4">
              
            <div v-if="this.selectedSchema.fields.includes('WSTAW PRODUKT')">

              <label>Wpisz nazwę produktu:</label>
              
              <b-input-group>
          
                <b-form-input v-model="topic" ref="productName" placeholder="np. tabletki odchudzające" v-on:keyup.enter="generateFinish()"></b-form-input>
                
              </b-input-group>

            </div>

            <div v-if="this.selectedSchema.fields.includes('WKLEJ TEKST')">

              <label>Wklej tekst do którego stworzone zostaną śródtytuły:</label>

              <b-input-group>

                <b-form-textarea v-model="pasteText" rows=10 ref="pasteText" placeholder="Wklej tutaj tekst"></b-form-textarea>
                
              </b-input-group>

            </div>

            
            <br/>
 
            <b-form-checkbox v-model="addEmoji" value="true">Dodaj emoji</b-form-checkbox>
 
          </div>

        </b-col>

      </b-row>
  
      <hr/>

      <b-row align-v="center" class="mt-2" style="min-height: 40px;">

        <b-col lg="6">
        
        </b-col>

        <b-col lg="6">

        <b-button variant="outline-success mr-1" ref="generatePost" @click="generateFinish()" class="mt-3 float-right">
          <span class="material-symbols-outlined align-middle">
            bolt
          </span>
          Generuj
        </b-button> 

        </b-col>

      </b-row>



      <div class="mt-2" >
  
        <b-card header-tag="header" class="mt-4" v-if="isFinish">

          <template #header>

            <h6 class="mb-0 mt-2 float-left">
              <span v-if="!finishContent"><b-spinner variant='secondary' small></b-spinner> AI generuje nagłówki dla Ciebie <small>(może to potrwać dłuższą chwilę. Nie odświeżaj strony!)</small></span>
              <span v-if="finishContent">Wygenerowane nagłówki</span>
            </h6>

            <b-button class="float-right p-0" variant="outline-white" @click="isFinish = false">

              <span class="material-symbols-outlined mt-1">close</span>

            </b-button>

          </template>

          <blockquote class="blockquote mb-0" v-if="result" style="font-size: 1em;">
            
            <div v-html="result" class="mb-3 mt-3 p-3" style="border: 1px dashed #f3f3f3; font-size: 1em; line-height: 1.7em;"></div>

            <div class="text-primary pointer small mt-1 float-right" @click="copyResults">

              <span class="material-symbols-sharp align-middle">content_copy</span>
              Kopiuj do schowka

            </div>

          </blockquote>

        </b-card>
  
  
        <textarea ref="selectedText" style="display: none;" v-model="selectedText" v-on:focus="$event.target.select()"></textarea>   
        <textarea ref="finishContent" style="display: none;" v-model="finishContent" v-on:focus="$event.target.select()"></textarea>

      </div>

    </div>

  </div>

</template>

<script>

  export default {
    name: 'AppHeadlineGeneratorAi',
    components: {
    },
    data() {
      return {
        isFinish: false,
        result: "",
        selectedText: '',
        topic: "",
        productName: "",
        occupation: "",
        comment: "",
        industry: "",
        name: "",
        tematyka: "",
        problem: "",
        client: "",
        w_czym_pomagasz: "",
        style: "",
        pasteText: "",
        addEmoji: false,
        addCallToFollow: false,
        addHashtags: false,
        finishContent: '',
        selectedSchema: null,
        schema: [
          {
            name: "'Dlaczego?'",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Dlaczego tylko nieliczni [GRUPA DOCELOWA] potrafią [KORZYSC]'," + 
                     "'Poznaj najskuteczniejszy sposób na [PROBLEM] i [KORZYSC]'," + 
                     "'Jak w mniej niż [EFEKT] osiągnąć [KORZYSC]'," + 
                     "'Poznaj sekret [GRUPA DOCELOWA] którzy prawie zawsze osiągają [KORZYSC]'," + 
                     "'Czy można w mniej niż [EFEKT] zdobyć [KORZYSC]? Tak, dlatego, że...'," + 
                     "'Śmiali się, gdy, kupowałem [PRODUKT] ale gdy zobaczyli [KORZYSC] to...'," + 
                     "'Czy wiesz, dlaczego tylko nieliczni [GRUPA DOCELOWA] potrafią [KORZYSC] w [EFEKT] albo krócej.'," + 
                     "'Do osób, które mają problem z [PROBLEM]'," + 
                     "'Ważna wiadomość do [GRUPA DOCELOWA], którzy mają problem z [PROBLEM]'," + 
                     "'Dlaczego 90% [GRUPA DOCELOWA] ma problem z [PROBLEM]'"
          },
          {
            name: "Emocjonalne",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Poznaj tą niepopularną metodę na [PROBLEM]. Efekty widoczne już po [EFEKT]'," + 
                     "'Oto jak osiągnąć [KORZYSC] inwestując w [PRODUKT]'," + 
                     "'Ty, zawodowy [GRUPA DOCELOWA] w [EFEKT]'," + 
                     "'Jak osiągnąć [KORZYŚĆ] z [WSTAW PRODUKT]? Przeczytaj ten tekst...'," + 
                     "'Jak [KORZYSC] bez wydawania fortuny?'," + 
                     "'Zobacz jak łatwo możesz [KORZYSC] w mniej niż [EFEKT]'," + 
                     "'Poznaj unikalne rozwiązanie problemu [PROBLEM], które działa w [EFEKT] i pozwala [KORZYSC]'," + 
                     "'W [EFEKT] osiągniesz [KORZYSC] Nie wierzysz? Przeczytaj to...'," + 
                     "'Sfrustrowany [GRUPA DOCELOWA] znalazł rozwiązanie problemu z [PROBLEM]'," + 
                     "'Daj mi [EFEKT] a zobaczysz pierwsze efekty'"
          },
          {
            name: "Problem - Rozwiązanie",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Po prostu najlepszy [PRODUKT] dla [GRUPA DOCELOWA] w tym przedziale cenowym'," + 
                     "'Prawdopodobnie najlepszy [PRODUKT] dla [GRUPA DOCELOWA] jaki pojawił się na rynku'," + 
                     "'W końcu dało nam się rozwiązać problem [PROBLEM],'," + 
                     "'Miałeś ostatnio problemy z [PROBLEM]? Poznaj [PRODUKT], który da Ci [KORZYSC] w mniej niż [EFEKT]'," + 
                     "'W końcu [GRUPA DOCELOWA] mają coś co [KORZYSC] w [EFEKT] albo szybciej.'," + 
                     "'Ważny komunikat dla [GRUPA DOCELOWA] mających problem z [PROBLEM]. Przeczytaj do końca…'," + 
                     "'Jak pozbyć się [PROBLEM] bez wyrzeczeń i opróżniania portfela?'," + 
                     "'Co takiego stosują [GRUPA DOCELOWA], że prawie zawsze [KORZYSC]'," + 
                     "'Tylko [EFEKT]. Tyle dzieli Cię od [KORZYSC] – poznaj rozwiązanie.'," + 
                     "'[PROBLEM] – to problem, który często zgłaszają [GRUPA DOCELOWA]. Rozwiązanie jest prostsze niż myślisz, poznasz je w tym poście...'"
          },
          {
            name: "'Jeżeli'",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Jeżeli nie chcesz mieć [KORZYSC] nie czytaj tego tekstu dalej...'," + 
                     "'A gdyby tak raz na zawsze pozbyć się problemu [PROBLEM] i mieć [KORZYSC] w mniej niż [EFEKT]'," + 
                     "'Czy popełniasz te błędy jako [GRUPA DOCELOWA]? Dowiedz się jak ich uniknąć?'," + 
                     "'Osiąganie [KORZYSC] w [EFEKT] jest możliwe oto dowód.'," + 
                     "'[GRUPA DOCELOWA] nie wierzyli, że można osiągnąć [KORZYSC] w [EFEKT]. To ich przekonało...'," + 
                     "'Czy potrzebujesz wielu lat, żeby pozbyć się problemu [PROBLEM]? Nie, wystarczy Ci [EFEKT] żeby bez trudu [KORZYSC]'," + 
                     "'Rozwiązanie kluczowego problemu [GRUPA DOCELOWA] -30% taniej.'," + 
                     "'Mamy to. Możesz mieć [KORZYSC] w [EFEKT]. Jak? Przeczytaj ten post do końca.'," + 
                     "'Szokujące wyznanie [GRUPA DOCELOWA]. Osiągnął [KORZYSC] nie tracąc lat, poświęcając jedynie [EFEKT].'," + 
                     "'To było do przewidzenia. [GRUPA DOCELOWA] oszaleli na punkcie [PRODUKT]. Czy nas to dziwi? Nie, bo można osiągnąć [KORZYSC] w mniej niż [EFEKT].'"  
          },
          {
            name: "AIDA – przyciągające uwagę",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'To co się wyprawia z [PRODUKT] przechodzi wszelkie pojęcie.  [GRUPA DOCELOWA] wykupują [PRODUKT] na pniu.'," + 
                     "'[PROBLEM] wydaje Ci się problemem nie do przeskoczenia? To chyba nie znasz [PRODUKT].'," + 
                     "'[GRUPA DOCELOWA] ciągle główkują, jak rozwiązać problem [PROBLEM]. Nasi klienci śpią spokojnie, bo mają [PRODUKT]. Szczegóły poznasz poniżej...'," + 
                     "'Mam już dość problemów z [PROBLEM]? Myślisz tak czasami? Nie przejmuj się, to częsty problem [GRUPA DOCELOWA], dlatego mamy dla Ciebie rozwiązanie, poznasz je w tym poście.'," + 
                     "'Ile jeszcze czasu dasz sobie wmawiać, że [GRUPA DOCELOWA] musi pogodzić się z problemem [PROBLEM]? Poznaj rozwiązanie, które zmienia reguły gry w [EFEKT] lub krócej. Szczegóły w tym wpisie.'," + 
                     "'A co, jeśli wszystko co udało Ci się przeczytać nt. problemów z [PROBLEM] było nieprawdą? Poznaj rozwiązanie, które daje efekty w [EFEKT].'," + 
                     "'Nie wierzyli we mnie, gdy inwestowałem w [PRODUKT], ale gdy zobaczyli efekty, zaczęli mnie pytać o szczegóły. To słowa wielu naszych klientów'," + 
                     "'Jedyne rozwiązanie, które tak szybko pokochali [GRUPA DOCELOWA] bez zadawania zbędnych pytań i setek emaili.'," + 
                     "'[PROBLEM] to już nie problem, jeżeli masz [PRODUKT]. [KORZYSC] w [EFEKT] przekonają nawet największych sceptyków.'," + 
                     "'Gdybym miał wybrać najskuteczniejszy sposób na [PROBLEM] bez wahania poleciłbym Ci [GRUPA DOCELOWA]. Dlaczego? Tego dowiesz się w tym wpisie.'"
          },
          {
            name: "NLP",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Dlaczego wybitni [GRUPA DOCELOWA] polecają [PRODUKT]? Wyjaśniam to w tym poście.'," + 
                     "'Nie wyobrażam sobie dnia bez [PRODUKT]? To częste słowa naszych klientów. Co ich tak urzekło w naszym rozwiązaniu? Przeczytaj ten post a wszystko będzie jasne.'," + 
                     "'Prawdziwy koszmar [GRUPA DOCELOWA] to już przeszłość. Zapomnij o problemach z [PROBLEM] bo [PRODUKT] skutecznie rozwiązuje ten problem. Jak, o tym poniżej.'," + 
                     "'[PROBLEM] wydaje się nie do pokonania, jednak my się nie poddaliśmy i są efekty. Wyobraź sobie, że [PRODUKT] pozwala [KORZYSC] w [EFEKT]. Jak to zrobić? O tym w dzisiejszym wpisie.'," + 
                     "'Na co Ci dziesiątki rozwiązań, czy nie lepiej mieć jedno, ale działające? Taki właśnie jest [PRODUKT] pozwala na [KORZYSC] w [EFEKT], albo krócej a to dopiero początek.'," + 
                     "'Jak sprytni [GRUPA DOCELOWA] znaleźli skuteczny sposób na problem [PROBLEM]'," + 
                     "'Co łączy [GRUPA DOCELOWA], które osiągnęły sukces w walce z [PROBLEM]? Zaskoczę Cię, nie potrzebowali oni wielu rozwiązań, wystarczyło im, że zainwestowali w [PRODUKT]. Od dziś [KORZYSC] nie tracąc czasu i pieniędzy.'," + 
                     "'Dlaczego tak niewiele osób wierzy w to, że problem [PROBLEM] można rozwiązać w szybki i skuteczny sposób? Powód jest jeden, nikt nie powiedział im o [PRODUKT]. Szczegóły poznasz w tym wpisie.'," + 
                     "'[PROBLEM] nie jest łatwym problemem do rozwiązania. Nie ma magicznego przycisku, który by ten problem zażegnał, jest jednak rozwiązanie, które działa i jest polecane przez [GRUPA DOCELOWA], mowa tutaj o polecanym w środowisku [PRODUKT]. Chcesz poznać szczegóły? Przeczytaj ten post do końca.'"
          },
          {
            name: "'A gdyby tak...'",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'...a gdyby tak rzucić wszystko i rozwiązać wreszcie problem [PROBLEM]? Jeżeli jesteś gotowy na [KORZYSC] przeczytaj o rewolucyjnym rozwiązaniu, którym jest [PRODUKT].'," + 
                     "'Zacznij stosować [PRODUKT] przez [EFEKT] a efekty zaskoczą Cię bardziej niż Ci się wydaje.'," + 
                     "'Nie myśl teraz o [PRODUKT], skup się raczej na rozwiązaniu problemu [PROBLEM] bo każdego dnia problem będzie miał coraz poważniejsze konsekwencje. Jak się za to zabrać? Przeczytasz o tym w tym wpisie.'," + 
                     "'Co za geniusz wymyślił [PRODUKT] – te słowa często padają z ust [GRUPA DOCELOWA] i w 100% się z nimi zgadzamy. Dowiedz się co ich tak urzekło...'," + 
                     "'Byłem krytykowany za to, że wierzę w reklamy, ale gdy inni [GRUPA DOCELOWA] zobaczyli jak [KORZYSC] w [EFEKT] nie mogłem opędzić się od pytań w stylu – gdzie to kupiłeś. – to częste słowa naszych klientów, którzy zdecydowali się na [PRODUKT]. Teraz czas na Ciebie.'," + 
                     "'Jak pozbyć się [PROBLEM] i nie zwariować? Odpowiedź jest prosta, przetestuj [PRODUKT]. Szczegóły poznasz w tym poście.'," + 
                     "'Jeżeli nadal nie słyszałeś o [PRODUKT] to prawdopodobnie nadal borykasz się z problemem [PROBLEM]. To nie Twoja wina. Dowiedz się jak naprawić ten błąd jak najszybciej.'," + 
                     "'Od wielu lat pomagamy [GRUPA DOCELOWA] rozwiązać palący problem [PROBLEM]. Efekty? 93% [GRUPA DOCELOWA] w czasie nie dłuższym niż [EFEKT] osiąga [KORZYSC]. Zainteresowany? Jeżeli tak to czytaj dalej ten post.'," + 
                     "'93% [GRUPA DOCELOWA] pozbywa się problemu [PROBLEM] w czasie nie dłuższym niż [EFEKT] stosując [PRODUKT]. Przypadek? Nie sądzę. Oto jak to działa.'," + 
                     "'Od kiedy stosuję [PRODUKT] nie mam problemów [PROBLEM]. Ty też tak chcesz? Szczegóły poznasz w tym wpisie.'" 
          },
          {
            name: "Efekt",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Czy można mieć lepsze efekty jako [GRUPA DOCELOWA]? Naturalnie, wystarczy wykorzystać potencjał [PRODUKT]. Jak to zrobić? Szczegóły opisuję w tym poście.'," + 
                     "'Mówili, że się nie da, ale ja chodzę swoimi ścieżkami i postanowiłem dać szansę [PRODUKT]. Teraz to ja się śmieję, gdy w mniej [EFEKT] mam [KORZYSC]. Ty też możesz iść za tłumem albo zacząć myśleć po swojemu.'," + 
                     "'Po tym co zafundował mi [PRODUKT] do dziś nie mogę się otrząsnąć. Uważam, że każdy [GRUPA DOCELOWA] powinien dać szansę temu rozwiązaniu. To częste słowa naszych klientów. Pamiętaj, że ty też możesz osiągnąć podobne efekty. Szczegóły poznasz w tym wpisie.'," + 
                     "'Dlaczego 90% [GRUPA DOCELOWA] nie osiąga zadawalających efektów? To proste, nie znają rozwiązań, które działają. To co chcę Ci zaproponować to [PRODUKT], który rozwiązuje problem [PROBLEM] w mniej niż [EFEKT]. Poznaj szczegóły i przeczytaj ten post do końca.'," + 
                     "'Oto post, który musi przeczytać każdy szanujący się [GRUPA DOCELOWA], który pragnie osiągać efekty a nie wymówki. Jeżeli chcesz pozbyć się problemów z [PROBLEM] przeczytaj ten tekst dom końca.'," + 
                     "'Zakładając, że tak jak większość [GRUPA DOCELOWA] masz już dosyć problemów z [PROBLEM] to nasze rozwiązanie, czyli [PRODUKT] będzie czymś co Cię zainteresuje. Poznaj szczegóły w tym wpisie.'," + 
                     "'Zignoruj ten post, jeżeli nadal, nie wierzysz, że problem [PROBLEM] można rozwiązać szybko i skutecznie. Jeżeli jednak nadal czytasz ten tekst to znaczy, że chcesz [KORZYSC] w mniej [EFEKT]. Co gwarantuje takie efekty? Poznaj [PRODUKT] o którym piszę w tym poście.'," + 
                     "[GRUPA DOCELOWA] mówią, że jest czas przed i po poznaniu [PRODUKT]. Mylą się? Mają rację? Szczegóły poznasz w tym wpisie.'," + 
                     "'Nigdy więcej nie zaczynaj dnia jako [GRUPA DOCELOWA] bez [PRODUKT]. Dlaczego? Bo problemy z [PROBLEM] będą się tylko nawarstwiały. Wystarczy, że zainwestujesz w [PRODUKT] a [KORZYSC] zrobią się same. więcej o tym rozwiązaniu w tym poście.'," + 
                     "'Pozwól się o coś zapytać? Czy nadal chcesz ignorować problem z [PROBLEM]? Jeżeli nie to poznaj rozwiązanie, które da Ci [KORZYSC] w czasie nie dłuższym niż [EFEKT]. Poznaj więcej szczegółów w tym poście.'" 
          },
          {
            name: "Prezentacja",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Przedstawiamy najnowszy pomysł na likwidację problemu [PROBLEM] w [EFEKT]. Chcesz wiedzieć więcej? Przeczytaj ten wpis do końca.'," + 
                     "'Co każdy [GRUPA DOCELOWA] powinien wiedzieć o problemie [PROBLEM]? odpowiedź na to pytanie znajdziesz w tym poście.'," + 
                     "'Nigdy więcej nie ignoruj problemu [PROBLEM], gdy masz gotowe rozwiązanie dające efekty [EFEKT]. Więcej dowiesz się w tym poście.'," + 
                     "'Proste rozwiązanie problemu [PROBLEM] teraz na wyciągnięcie ręki. Wypróbuj [PRODUKT] i bądź z siebie dumny. Szczegóły poznasz w tym wpisie.'," + 
                     "'Pozbądź się raz na zawsze problemu [PROBLEM] stosując [PRODUKT]. Jeżeli chcesz wiedzieć więcej przeczytaj ten post do końca.'," + 
                     "'Mało znany, ale szalenie skuteczny sposób, żeby pozbyć się problemu [PROBLEM] nie czekając na efekt dłużej niż [EFEKT].'," + 
                     "'1 typ [GRUPA DOCELOWA], który poradził sobie z problemem [PROBLEM] stosuje tą tajną broń. Poznaj efekty, które daje [PRODUKT].'," + 
                     "'Ważna aktualizacja! Dotyczy [GRUPA DOCELOWA], którzy nie do końca radzą sobie z problemem [PROBLEM]. Jest wreszcie rozwiązanie na miarę Twoich potrzeb. Szczegóły poznasz w tym wpisie.'," + 
                     "'Czy przyłapujesz się na tym, że odechciewa Ci szukania rozwiązań problemów związanych z [PROBLEM]. To nie Twoja wina, taka postawa widoczna jest u wielu zmęczonych [GRUPA DOCELOWA], dlatego mam dla Ciebie sprawdzone rozwiązanie. W tym poście poznasz [PRODUKT], który zmienia problem w rozwiązanie.'," + 
                     "'[GRUPA DOCELOWA] są wściekli, bo nikt nie powiedział im o [PRODUKT]. Naprawiam to w tym wpisie.'"
          },
          {
            name: "'To głupie'",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'To głupie, że nadal masz problem z [PROBLEM], gdy na rynku dostępny jest [PRODUKT]. Kilka tysięcy [GRUPA DOCELOWA] już korzysta z tego rozwiązania, czas dołączyć do grona zwycięzców.'," + 
                     "'Nowość! [PRODUKT], który rozwiązuje problem [PROBLEM] i pozwala osiągnąć [KORZYSC] w mniej [EFEKT]. Oto więcej szczegółów.'," + 
                     "'Czy przesadzę, jeżeli napiszę, że rozwiązanie problemu [PROBLEM] jest tak naprawdę na wyciągniecie ręki? To co mamy dzisiaj dla Ciebie to [PRODUKT], który [KORZYSC] w czasie nie dłuższym niż [EFEKT].'," + 
                     "'Co pomyślisz o sobie za kilka miesięcy, jeżeli dziś zignorujesz ten skuteczny sposób na rozwiązanie problemu [PROBLEM]? Czy możesz przejść obok rozwiązania, które [KORZYSC] w czasie nie dłuższym niż [EFEKT]. Ten post może wiele zmienić w twoim życiu, dlatego przeczytaj go do końca.'," + 
                     "'Zapytaj mnie o rozwiązanie problemu [PROBLEM] a ja podpowiem Ci [PRODUKT]. Dlaczego? Skąd ta pewność? [KORZYSC] w czasie nie dłuższym niż [EFEKT] i do tego tysiące [GRUPA DOCELOWA], którzy stoją murem za [PRODUKT]. Wystarczy? Jeżeli nie to czytaj dalej.'," + 
                     "'Możesz pozbyć się problemu [PROBLEM] jeżeli zastąpisz narzekanie [PRODUKT]. Więcej szczegółów w poście.'," + 
                     "'Złam kod sukcesu najskuteczniejszych [GRUPA DOCELOWA] i przestań zamartwiać się problemem [PROBLEM]. Rozwiązanie znajdziesz w tym poście.'," + 
                     "'Poznaj zakulisowe sekrety [GRUPA DOCELOWA], którzy wiedzą jak [KORZYSC] w czasie nie dłuższym niż [EFEKT]'," + 
                     "'Dlaczego ostatnie egzemplarze [PRODUKT] dziś prawdopodobnie znikną z naszego magazynu?'," + 
                     "'Brakuje Ci pomysłów na rozwiązanie problemów z [PROBLEM]? Prawdopodobnie nie wiesz o [PRODUKT].'"
          },
          {
            name: "Strata",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Jeżeli nie chcesz stracić szansy na skuteczne rozwiązanie problemów z [PROBLEM] przeczytaj ten post do końca.'," + 
                     "'Tylko przez moment, z okazji xxx obniżamy cenę [PRODUKT] o 30%.'," + 
                     "'Kto jeszcze chce przestać martwić się [PROBLEM]? Sposób opisuje w tym poście.'"
          },
          {
            name: "2 korzyści",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Jak [KORZYSC 1] i [KORZYSC 2] w mniej niż [CZAS OSIĄGNIĘCIA EFEKTU]'," + 
                     "'Dlaczego tylko nieliczni [GRUPA DOCELOWA] osiągają [KORZYSC] i [KORZYSC 2] w czasie krótszym niż [CZAS OSIĄGNIĘCIA EFEKTU]'," + 
                     "'Poznaj sekret [GRUPA DOCELOWA] którzy osiągają [KORZYSC 1] i [KORZYSC 2] w czasie krótszym niż [CZAS OSIĄGNIĘCIA EFEKTU]”," + 
                     "'Czy zastanawiasz się czasami, dlaczego tylko nieliczni [GRUPA DOCELOWA] mają [KORZYSC 1] oraz [KORZYSC 2] w czasie, gdy inni [GRUPA DOCELOWA] ponoszą porażkę za porażką?'," + 
                     "'Jak [KORZYSC 1] i [KORZYSC 2]'," + 
                     "'Jak nasz klient [KORZYSC 1] i [KORZYSC 2] w mniej niż [CZAS OSIĄGNIĘCIA EFEKTU]'," + 
                     "'Posłuchaj jak nasz klient [KORZYSC 1] i [KORZYSC 2] w mniej niż [CZAS OSIĄGNIĘCIA EFEKTU]'," + 
                     "'Kto jeszcze chce mieć [KORZYSC 1] i [KORZYSC 2]'," + 
                     "'Kto jeszcze chce [KORZYSC 1] i [KORZYSC 2] w mniej niż [CZAS OSIĄGNIĘCIA EFEKTU]'"  
          },
          {
            name: "Kosztowna pomyłka",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Mały błąd, który kosztowała [GRUPA DOCELOWA] [KOSZT BLEDU]'," + 
                     "'Ten głupi błąd kosztowała [GRUPA DOCELOWA] [KOSZT BLEDU]. Czy chcesz uchronić się przed taką stratą?'," + 
                     "'Unikaj częstego błędu [GRUPA DOCELOWA] którzy potrafią stracić nawet [KOSZT BLEDU] na nieprzemyślanych decyzjach.'," + 
                     "'Nie popełniaj błędu [GRUPA DOCELOWA] którzy stracili nawet [KOSZT BLEDU] słuchając niewłaściwych osób.'"
          },
          {
            name: "Cyfry",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. Oto schematy:" + 
                     "'Poznaj te [WSTAW CYFRĘ] sposobów na [WSTAW PROBLEM GRUPY DOCELOWEJ]'," + 
                     "'Czy znasz te [WSTAW CYFRĘ] sposobów na rozwiązanie problemu [WSTAW PROBLEM GRUPY DOCELOWEJ]'," + 
                     "'Tylko [WSTAW CYFRĘ 5 lub 7] kroków dzieli Cię od rozwiązania [WSTAW PROBLEM GRUPY DOCELOWEJ]'," + 
                     "'Poznaj te [WSTAW CYFRĘ 5 lub 7] powodów, dla których warto zainwestować w [WSTAW NAZWĘ PRODUKTU]'," + 
                     "'Od kiedy mam [WSTAW NAZWĘ PRODUKTU] pozbyłem się tych [WSTAW CYFRĘ 5 lub 7] problemów z wynikających z [WSTAW PROBLEM GRUPY DOCELOWEJ]'," + 
                     "'Odkryj te [WSTAW CYFRĘ 5 lub 7] powodów, dla których opłaca się zainwestować w [WSTAW NAZWĘ PRODUKTU] każde pieniądze.'"
          },
          {
            name: "Dowód społeczny",
            icon: "notes",
            fields: ["WSTAW PRODUKT"],
            command: "Stwórz 10 nagłówków copywritera do oferty dla produktu: [WSTAW PRODUKT] na podstawie poniższych schematów. Użyj power words i bezpośredniego języka. W pole [LICZBA KLIENTOW] wstaw jakąś liczbę klientów. Oto schematy:" + 
                     "'Już [LICZBA KLIENTOW] [NAZWA GRUPY DOCELOWEJ] cieszy się z korzyści z posiadania [WSTAW PRODUKT]'," + 
                     "'SZOK! Aż [LICZBA KLIENTOW] [NAZWA GRUPY DOCELOWEJ] jest z nami na pokładzie. Dołącz do grona szczęśliwych posiadaczy [WSTAW PRODUKT].'," + 
                     "'Dlaczego warto zainwestować w [WSTAW PRODUKT]? Dlatego, że zrobiło to [LICZBA KLIENTOW] [NAZWA GRUPY DOCELOWEJ].'," + 
                     "'Liczba osób, które na zaufało jest niesamowita. Aż [LICZBA KLIENTOW] [NAZWA GRUPY DOCELOWEJ] korzysta z [WSTAW PRODUKT].'"
          },
          {
            name: "Generator śródtytułów",
            icon: "menu_open",
            fields: ["WKLEJ TEKST"],
            command: "Zaraz wkleję Ci tekst a twoje zadanie ma polegać na stworzeniu 10 krótkich, copywriterskich nagłówków, które będę przekonywały do przeczytania poniższego tekstu. Zaczynaj nagłówki od Jak, Dlaczego, Poznaj Sekrek, Dlaczego tylko, Dlaczego nieliczyni. Oto tekst: [WKLEJ TEKST]"
          }
        ]
      }
    },
    methods: {

      selectSchema: function(item) {

        this.selectedSchema = item
      },

      generateFinish: function() {

        this.$refs.generatePost.disabled = true

        this.isFinish = true

        this.finishContent = ""
        this.result = ""

        var query = "";
  
        query = this.selectedSchema.command;

 
        if(this.$refs.productName)
          query = query.replaceAll('[WSTAW PRODUKT]', this.$refs.productName.value)

        if(this.$refs.pasteText)
          query = query.replaceAll('[WKLEJ TEKST]', this.pasteText)
 
          
        if(this.addEmoji) {

          query = query + "Dodaj emoji."

        }

   

        var doneText = "";

        this.axios.post(window.API_URL + "apps/aiPostCreator/getAiText/", { 'text': query }, { withCredentials: true, timeout: 3000000 }).then((response) => {
  
          let data = response.data

          if(data.redirectTo) {

            top.location.href = data.redirectTo;

          }

          doneText = data.result;

          this.finishContent = doneText;

          this.result = doneText.replace(/(?:\r\n|\r|\n)/g, '<br/>');

          this.$refs.generatePost.disabled = false
          this.generatedPostTitle = "Wygenerowane nagłówki"

        }).catch(function() {

          this.$refs.generatePost.disabled = false

         })
 
        
 
      },

      loadFromExample: function(keyword) {

        this.topic = keyword

      },

      copyResults: function() {
 
        this.$refs.finishContent.style.display = "block";
        
        this.$refs.finishContent.focus();

        document.execCommand('copy');
         
        this.$refs.finishContent.style.display = "none"

        window.scrollTo(0, 0)

        this.$bvToast.toast('Post został skopiowany do schowka', {
          title: 'Skopiowano',
          autoHideDelay: 5000,
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-top-center',
          noCloseButton: true
        })

      } 

    },
    computed: {
  
    },
    created() {

      this.$watch('finishContent', (value) => {

        var lines = value.split(/\r|\r\n|\n/);

        this.finishLinesCount = lines.length;

      })
 
    }

  }
</script>

<style>

  .pointer { cursor: pointer; }
  .bold { font-weight: 600 !important; }
</style>