<template> 

  <div class="container pt-4">
      
    <Loader v-if="isLoading"/>
      
    <div v-if="!schemaId">Select</div>

    <div v-if="schemaId && !elementId">
     
      Wybierz wiadomość i wygeneruj ją w kreatorze:

      <b-list-group class="mt-4">

        <b-list-group-item button v-for="item in schemaData" :key="item.id" v-on:click="loadElement(item.id)">
          <b class="text-primary">{{ item.title }}</b><br/>
          <small v-html="item.description"></small>
        </b-list-group-item>

      </b-list-group>

    </div>

    <div v-if="elementId">
        <h5> 

          <b-button class="align-middle" variant="outline-white" @click="elementId = null">
            <span class="material-symbols-outlined">
              navigate_before
            </span>
          </b-button>
          
          <B>{{ elementData.title }}</B>
        
        </h5>

        <hr/>

        <div class="text-center">

          <span :class="{ 'font-weight-bold text-primary': (page == 'vars') }">
            <span class="material-symbols-outlined align-middle">
              looks_one
            </span> 
            Uzupełnij Informacje
          </span> 
           
          <span class="ml-2" :class="{ 'font-weight-bold text-primary': (page == 'creator') }">
            <span class="material-symbols-outlined align-middle">
              looks_two
            </span> 
            Edytuj Zawartość
          </span> 
           
          <span class="ml-2" :class="{ 'font-weight-bold text-primary': (page == 'finish') }">
            <span class="material-symbols-outlined align-middle">
              looks_3
            </span> 
            Wygeneruj Treść
          </span> 

        </div>



        <div v-show="page == 'vars'">

          <div v-for="item in elementData.fields" :key="item" class="mt-4">

            <b-card>
              <b-card-text>
                <B>{{ schemaFields.find(x => x.key === item).title }}:</B>
                <input class="form-control mt-2" :id="item" :data-name="item" ref="vars" type="text" :placeholder="schemaFields.find(x => x.key === item).example"/>
              </b-card-text>
            </b-card>

          </div>

          <b-button class="mt-3 mb-5 float-right" variant="outline-primary" @click="page = 'creator'">
            Dalej <span class="material-symbols-outlined align-middle">keyboard_double_arrow_right</span>
          </b-button>

        </div>
   
        
        <div v-show="page == 'creator'">

          <div class="text-center mt-3">
            <span class="material-symbols-outlined">
              keyboard_double_arrow_down
            </span>
          </div>

          <div class="text-center mt-1">
            Postęp budowy treści
          </div>
  
          <b-card no-body class="mt-3">

            <b-tabs pills card vertical>
              <b-tab v-for="item in elementData.steps" :title="(item.id + '. ' + item.title)" :key="item.id">
                <b-card-text>
                  <B>{{ item.title }}</B><br/>
                  <small>{{ item.description }}</small>

                  <select ref="selected" class="form-control mt-2" @click="selectOptionsClick(item.id)" :data-step="item.id" @change="selectChange(item.id, $event)"></select>

                  <textarea class="form-control mt-2" :data-step="item.id" :data-name="item.name" ref="steps" rows="6"></textarea>
                </b-card-text>
              </b-tab>
            </b-tabs> 

          </b-card>

          <b-button class="mt-3 mb-5 float-left" variant="outline-primary" @click="page = 'vars'">
            <span class="material-symbols-outlined align-middle">keyboard_double_arrow_left</span> Wróć
          </b-button> 

          <b-button class="mt-3 mb-5 float-right" variant="outline-primary" @click="page = 'finish'; generate()">
            Wygeneruj e-mail <span class="material-symbols-outlined align-middle">keyboard_double_arrow_right</span>
          </b-button> 
        
        </div>

        <div v-show="page == 'finish'">

          <b-card class="mt-3">
            <b-card-text>
                <h5><span class="material-symbols-outlined align-middle">mail</span> {{ output_subject }}</h5>
                Od: <b-badge variant="light">twoj@mail.pl</b-badge> do: <b-badge variant="success">info@firma.pl</b-badge>
                <hr/>
                <div v-html="output"></div>
            </b-card-text>
          </b-card>
 
          <b-button class="mt-3 mb-5 float-left" variant="outline-primary" @click="page = 'creator'">
            <span class="material-symbols-outlined align-middle">keyboard_double_arrow_left</span> Wróć
          </b-button> 


        </div>

 


    </div>

  </div>
</template>

<script>
  import Loader from '../../Loader';

  export default {
    name: 'AppInfluencerGenerator',
    components: {
      Loader
    },
    data() {
      return {
         schemaId: null,
         elementId: null,
         schemaData: '',
         elementData: '',
         schemaFields: '',
         output: '',
         output_subject: '',
         page: 'vars',
         isLoading: false
      }
    },
    methods: {
      
      generate: function() {

        var result = ""

        this.$refs.steps.forEach((step) => {

          if(step.getAttribute("data-name") != "subject") {

            result = result + step.value + "\n\n"

          }
        
          if(step.getAttribute("data-name") == "subject") {

            this.output_subject = step.value

          }

        })

        result = result.replace(/(?:\r\n|\r|\n)/g, '<br>')

        this.output = result

      },

      selectOptionsClick: function(id) {

        this.$refs.selected.forEach((item) => {

          if(item.getAttribute("data-step") == id) {

            item.innerText = null;

            item.add(new Option(""));
 
            this.elementData.steps.find(x => x.id === id).items.forEach((option) => {

              var text = option

              this.schemaFields.forEach((sf) => {

                var filledVar = ""

                this.$refs.vars.forEach((var_item) => {

                  if(var_item.getAttribute("data-name") == sf.key) {
 
                    filledVar = var_item.value

                  }
                   
                });

                text = text.replace(new RegExp("\\[" + sf.key + "\\]", "gi"), filledVar);
                text = text.replace(new RegExp("\\[NL\\]", "gi"), "\n\n");

              })

              item.add(new Option(text, text));

            })

          }

        })
 
      },

      selectChange: function(id, text) {

        this.$refs.steps.forEach((item) => {

          if(item.getAttribute("data-step") == id) {
 
            item.value = text.target.value

          }

        })
        
      },

      loadElement: function(id) {

        this.schemaData.forEach((item) => {

          if(item.id == id) {

            this.elementId = item.id
            this.elementData = item
 
          }

        })
      },

      getSchema: function () {

        this.isLoading = true

        this.axios.get(window.API_URL + "apps/influencerGenerator/getSchemaData?id=" + this.schemaId).then((response) => {

          this.schemaData = response.data

          this.axios.get(window.API_URL + "apps/influencerGenerator/getSchemaFields?id=" + this.schemaId).then((response) => {

            this.schemaFields = response.data

            this.isLoading = false

            this.page = 'vars'

          });

        })

      } 
   

    },
    created() {

      this.schemaId = 1
      this.getSchema()


      if(this.$route.params.schemaId) {

        this.schemaId = this.$route.params.schemaId
        this.getSchema()

      }


    },
    watch: {

      '$route.params.schemaId' (schemaId) {

        this.schemaId = schemaId
        this.getSchema()

      }

    }

  }
</script>

<style>

  .pointer { cursor: pointer; }

</style>